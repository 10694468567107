import { AxiosResponse } from "axios";
import { createAxiosInstanceWithAuthInterceptor } from "../../utils/axiosHelper";
import { keysToCamel, keysToSnake } from "../../utils/objects";
import { handleAuthError } from "../../utils/auth";
import {
  Bottle,
  CreateDistilleryType,
  Distillery,
  GetDistilleryJuicesResponse,
  Juice,
  Submission,
  User,
  Store,
  Entry,
  Collectable,
  BottleSearchResult,
} from "./types";

const baseUrl = "https://api.onlydrams.app/api/v1";
const odisUrl = "https://api.onlydrams.app/odis/v1";

const serverInstance = createAxiosInstanceWithAuthInterceptor(baseUrl);

serverInstance.interceptors.response.use(
  function (response) {
    return keysToCamel(response) as AxiosResponse<any>;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      return handleAuthError(serverInstance, error);
    }
    return Promise.reject(error);
  }
);

serverInstance.interceptors.request.use(
  function (config) {
    if (config.data) {
      config.data = keysToSnake(config.data);
    }
    return config;
  },
  (error) => handleAuthError(serverInstance, error)
);

export const getDistilleries = async (
  searchTerm: string,
  page: number
): Promise<{ data: Distillery[]; total: number }> => {
  const response = await serverInstance.get(
    `${baseUrl}/distilleries?${
      searchTerm.length
        ? `query=${encodeURIComponent(searchTerm)}&page=${page}`
        : `page=${page}`
    }`
  );
  return response.data;
};

export const getUsers = async (
  searchTerm: string,
  page: number
): Promise<{ data: User[]; total: number }> => {
  const response = await serverInstance.get(
    `${odisUrl}/users?${
      searchTerm.length
        ? `query=${encodeURIComponent(searchTerm)}&page=${page}`
        : `page=${page}`
    }`
  );
  return response.data;
};

export const getUsersCollection = async (
  id: string,
  searchTerm: string,
  page: number
): Promise<{ data: Collectable[]; total: number }> => {
  const response = await serverInstance.get(
    `${odisUrl}/users/${id}/collection?${
      searchTerm.length
        ? `query=${encodeURIComponent(searchTerm)}&page=${page}`
        : `page=${page}`
    }`
  );
  return response.data;
};

export const searchBottles = async (
  searchTerm: string,
  page: number
): Promise<{ data: BottleSearchResult[]; total: number }> => {
  const response = await serverInstance.get(
    `${odisUrl}/bottles/search?${
      searchTerm.length
        ? `query=${encodeURIComponent(searchTerm)}&page=${page}`
        : `page=${page}`
    }`
  );
  return response.data;
};

export const getStores = async (
  searchTerm: string,
  page: number
): Promise<{ data: Store[]; total: number }> => {
  const response = await serverInstance.get(
    `${odisUrl}/stores?${
      searchTerm.length
        ? `query=${encodeURIComponent(searchTerm)}&page=${page}`
        : `page=${page}`
    }`
  );
  return response.data;
};

export const getDistillery = async (id: string): Promise<Distillery> => {
  const response = await serverInstance.get(`${odisUrl}/distilleries/${id}`);
  return response.data;
};

export const getUser = async (id: string): Promise<User> => {
  const response = await serverInstance.get(`${odisUrl}/users/${id}`);
  return response.data;
};

export const getJuices = async (
  searchTerm: string,
  page: number
): Promise<{ data: Juice[]; total: number }> => {
  const response = await serverInstance.get(
    `${odisUrl}/juices?${
      searchTerm.length
        ? `query=${encodeURIComponent(searchTerm)}&page=${page}`
        : `page=${page}`
    }`
  );
  return response.data;
};

export const updateDistillery = async (id: string, distillery: Distillery) => {
  const response = await serverInstance.put(`${odisUrl}/distilleries/${id}`, {
    distillery: distillery,
  });
  return response.data;
};

export const updateCategory = async (
  id: string,
  name: string,
  live: boolean
) => {
  const response = await serverInstance.patch(
    `${odisUrl}/admin/categories/${id}`,
    {
      category: { id, name, live },
    }
  );
  return response.data;
};

export const saveEntry = async (entry: Entry): Promise<Entry> => {
  const response = await serverInstance.post(`${odisUrl}/entries`, {
    entry: entry,
  });
  return response.data;
};

export const addToCollection = async (userId: string, bottleId: string) => {
  const response = await serverInstance.post(
    `${odisUrl}/users/${userId}/collection/add`,
    {
      bottle_id: bottleId,
    }
  );
  return response.data;
};

export const getDistilleryJuices = async (
  id: string,
  searchTerm: string,
  page: number
): Promise<GetDistilleryJuicesResponse> => {
  const response = await serverInstance.get(
    `${odisUrl}/distilleries/${id}/juices?page=${page}${
      searchTerm.length ? `&query=${encodeURIComponent(searchTerm)}` : ""
    }`
  );

  return response.data;
};

export const updateJuice = async (juice: Juice): Promise<Juice> => {
  const response = await serverInstance.put(`${odisUrl}/juices/${juice.id}`, {
    juice: juice,
  });
  return response.data;
};

export const deleteJuice = async (juiceId: string) => {
  const response = await serverInstance.delete(`${odisUrl}/juices/${juiceId}`);
  return response.data;
};

export const createJuice = async (juice: Juice): Promise<Juice> => {
  const juiceObj = {
    ...juice,
    distillery_id: juice.distillery.id,
    distillery: undefined,
  };
  const response = await serverInstance.post(`${odisUrl}/juices`, {
    juice: juiceObj,
  });
  return response.data;
};

export const getJuice = async (juiceId: string): Promise<Juice> => {
  const response = await serverInstance.get(`${baseUrl}/juices/${juiceId}`);
  return response.data;
};

export const getBottle = async (bottleId: string): Promise<Bottle> => {
  const response = await serverInstance.get(`${baseUrl}/bottles/${bottleId}`);
  return response.data;
};

export const createBottle = async (bottle: Bottle): Promise<Bottle> => {
  const response = await serverInstance.post(`${baseUrl}/bottles`, {
    bottle: bottle,
  });
  return response.data;
};

export const updateBottle = async (bottle: Bottle): Promise<Bottle> => {
  const response = await serverInstance.patch(
    `${baseUrl}/bottles/${bottle.id}`,
    { bottle: bottle }
  );
  return response.data;
};

export const getProducersList = async (): Promise<
  { id: string; name: string }[]
> => {
  const response = await serverInstance.get(`${baseUrl}/producers`);
  return response.data;
};

export const createDistillery = async (
  distillery: CreateDistilleryType
): Promise<Distillery> => {
  const response = await serverInstance.post(`${odisUrl}/distilleries`, {
    distillery: distillery,
  });
  return response.data;
};

export const getUnassignedSubmissions = async (
  page: number,
  searchTerm: string
): Promise<{ data: Submission[]; total: number }> => {
  const response = await serverInstance.get(
    `${odisUrl}/submissions?${
      searchTerm.length
        ? `query=${encodeURIComponent(searchTerm)}&page=${page}`
        : `page=${page}`
    }`
  );
  return response.data;
};

export const getMyAssignedSubmissions = async (
  page: number
): Promise<{ data: Submission[]; total: number }> => {
  const response = await serverInstance.get(`${odisUrl}/submissions/assigned`);
  return response.data;
};

export const getUPCs = async (
  bottleId: string
): Promise<{ id: string; code: string }[]> => {
  const response = await serverInstance.get(
    `${baseUrl}/bottles/${bottleId}/upcs`
  );
  return response.data;
};

export const addUPC = async (
  bottleId: string,
  code: string
): Promise<{ id: string; code: string }> => {
  const response = await serverInstance.post(
    `${baseUrl}/bottles/${bottleId}/upcs`,
    { upc: { code: code } }
  );
  return response.data;
};

export const deleteSubmission = async (code: string) => {
  const response = await serverInstance.delete(
    `${odisUrl}/submissions/${code}`
  );
  return response.data;
};

export const deleteUpc = async (bottleId: string, code: string) => {
  const response = await serverInstance.delete(
    `${odisUrl}/bottles/${bottleId}/upcs/${code}`
  );
  return response.data;
};

export const completeSubmission = async (code: string) => {
  const response = await serverInstance.post(
    `${odisUrl}/submissions/${code}/complete`,
    { code: code }
  );
  return response.data;
};

export const assignToSelf = async (code: string) => {
  const response = await serverInstance.post(
    `${odisUrl}/submissions/${code}/assign`,
    { code: code }
  );
  return response.data;
};

export const updateBarcode = async (upc: string, note: string) => {
  const response = await serverInstance.patch(`${baseUrl}/upcs/${upc}`, {
    barcode: { note: note },
  });
  return response.data;
};

export const deleteBottle = async (bottleId: string) => {
  const response = await serverInstance.delete(
    `${baseUrl}/bottles/${bottleId}`
  );
  return response.data;
};

export const deleteDistillery = async (distilleryId: string) => {
  const response = await serverInstance.delete(
    `${odisUrl}/distilleries/${distilleryId}`
  );
  return response.data;
};

export const getDashboard = async () => {
  const response = await serverInstance.get(`${odisUrl}/dashboard/stats`);
  return response.data;
};

export const confirmUser = async (userId: string) => {
  const response = await serverInstance.post(
    `${odisUrl}/users/${userId}/confirm`
  );
  return response.data;
};

export const getProducts = async () => {
  const response = await serverInstance.get(`${odisUrl}/products`);
  return response.data;
};

export const addInventory = async (
  storeId: string,
  productId: string,
  quantity: number,
  unlimited: boolean
) => {
  const response = await serverInstance.post(
    `${odisUrl}/stores/${storeId}/products`,
    {
      product_id: productId,
      quantity: quantity,
      unlimited: unlimited,
    }
  );
  return response.data;
};

export const getCategories = async (page: number) => {
  const response = await serverInstance.get(`${odisUrl}/admin/categories`, {
    params: { page },
  });
  return response.data;
};

export const addCategory = async (name: string, live: boolean) => {
  const response = await serverInstance.post(`${odisUrl}/admin/categories`, {
    category: { name, live },
  });
  return response.data;
};

export const saveRejectReason = async (code: string, reason: string) => {
  const response = await serverInstance.patch(
    `${odisUrl}/submissions/${code}/reject`,
    {
      reason,
    }
  );

  return response.data;
};
