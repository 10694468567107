import React from "react";
import { useGetUser } from "../../api/server/queries";
import UserOverview from "./UserOverview";
import UserCollection from "./UserCollection";
import { useConfirmUserMutation } from "../../api/server/mutations";
import { toast } from "sonner";
import avatar from "../../assets/default.png";

interface UserDetailsProps {
  userId: string;
}

export default function UserDetails({ userId }: UserDetailsProps) {
  const { data: user, isLoading, error, refetch } = useGetUser(userId);
  const confirmUserMutation = useConfirmUserMutation();

  const handleConfirmClick = async () => {
    confirmUserMutation.mutate(userId, {
      onSuccess: (response) => {
        const successMessage =
          response.message || "User confirmed successfully";
        toast.success(successMessage);
        refetch();
      },
      onError: (error: any) => {
        console.error("Confirmation error:", error);
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "Error confirming user";
        toast.error(errorMessage);
      },
    });
  };

  const SkeletonLoader = () => (
    <div className="animate-pulse">
      <div className="bg-white border-b">
        <div className="px-4 lg:mx-auto lg:max-w-6xl">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                <div className="hidden h-16 w-16 rounded-full bg-gray-200 sm:block"></div>
                <div className="ml-3">
                  <div className="h-8 bg-gray-200 rounded w-48 mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-64 mb-2"></div>
                  <div className="h-6 bg-gray-200 rounded w-24"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="h-48 bg-gray-200 rounded"></div>
      </div>
      <div className="mt-6">
        <div className="h-64 bg-gray-200 rounded"></div>
      </div>
    </div>
  );

  if (isLoading) return <SkeletonLoader />;
  if (error) return <div>Error loading user details</div>;
  if (!user) return <div>User not found</div>;

  return (
    <div>
      <div className="bg-white border-b">
        <div className="px-4 lg:mx-auto lg:max-w-6xl">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                <img
                  className="hidden h-16 w-16 rounded-full sm:block"
                  src={user.avatarUrl || avatar}
                  alt=""
                />
                <div>
                  <div className="flex items-center">
                    <img
                      className="h-16 w-16 rounded-full sm:hidden"
                      src={user.avatarUrl || avatar}
                      alt=""
                    />
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                      {user.username}
                    </h1>
                  </div>
                  <dl className="mt-3 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dd className="flex items-center text-sm font-medium text-gray-500 sm:mr-6">
                      {user.email}
                    </dd>
                    <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                      {user.confirmed ? (
                        <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                          Confirmed
                        </span>
                      ) : (
                        <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                          Unconfirmed
                        </span>
                      )}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-6 flex space-x-3 md:ml-4 md:mt-0">
              {!user.confirmed && (
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={handleConfirmClick}
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <UserOverview user={user} />
      <UserCollection userId={userId} />
    </div>
  );
}
