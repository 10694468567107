import { Juice } from "../../api/server/types";
import { Link } from "react-router-dom";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import {
  StarIcon,
  HomeIcon,
  MapPinIcon,
  WifiIcon,
} from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import { AddEditJuiceDrawer } from "../AddEditJuiceDrawer";
import { useDeleteJuiceMutation } from "src/api/server/mutations";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { DISTILLERY_JUICES_QUERY } from "src/api/server/queries";

export const DistilleryJuiceCard = ({ juice }: { juice: Juice }) => {
  const queryClient = useQueryClient();

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeletModalOpen, setDeletModalOpen] = useState(false);

  const totalBottles = juice?.sizes?.length || 0;
  const totalReviewCount = juice?.reviewCount || 0;

  const removeHandle = () => {
    setDeletModalOpen(true);
  };

  const deleteJuiceMutation = useDeleteJuiceMutation();

  const handleDeleteJuice = () => {
    deleteJuiceMutation.mutate(juice?.id, {
      onSuccess: () => {
        toast.success("Juice deleted successfully!");
        setDeletModalOpen(false);
        queryClient.invalidateQueries(DISTILLERY_JUICES_QUERY);
      },
      onError: () => {
        toast.error("Error deleting juice");
      },
    });
  };

  const onEditSuccessHandle = () => {
    queryClient.invalidateQueries(DISTILLERY_JUICES_QUERY);
  };

  return (
    <>
      <div className='rounded-xl border bg-card hover:bg-border/30 transition-colors text-card-foreground p-4 relative'>
        <Link
          to={`/juices/${juice.id}`}
          className='flex flex-col md:flex-row gap-5'
        >
          <div className='w-36 h-28 bg-black/5 rounded-lg overflow-hidden'>
            {juice?.imageUrl && (
              <img
                src={juice?.imageUrl}
                alt={juice?.name}
                className='size-full flex-none p-1 object-contain'
              />
            )}
          </div>

          <div className='min-w-0 flex-1'>
            <div className='flex items-start pr-8'>
              <h3 className='text-lg font-semibold  text-black'>
                {juice?.name}
              </h3>

              <span className='ml-auto inline-flex items-center rounded-md  px-2 py-1 text-xs  text-cloud-900 border border-cloud-100 bg-cloud font-medium text-nowrap'>
                {totalBottles} {totalBottles > 1 ? "bottles" : "bottle"}
              </span>
            </div>

            <p className='text-fog text-sm font-medium capitalize'>
              {juice?.variety}
            </p>

            <div className='flex items-center my-2'>
              <div className='flex'>
                {[1, 2, 3, 4, 5].map(rating => (
                  <StarIcon
                    key={rating}
                    className={clsx("h-5 w-5 flex-shrink-0", {
                      "text-yellow-400": (juice?.reviewRating ?? 0) >= rating,
                    })}
                    aria-hidden='true'
                  />
                ))}
              </div>

              <span className='text-fog font-medium text-xs mx-2'>
                {juice.reviewRating || 0}
              </span>
              <span className='text-fog text-xs'>
                {totalReviewCount} {totalReviewCount > 1 ? "reviews" : "review"}
              </span>
            </div>

            <div className='flex gap-x-4 flex-wrap text-fog text-sm'>
              <div className='flex gap-1'>
                <MapPinIcon className='w-4 h-auto' />
                {juice.origin || "Unknown"}
              </div>

              <div className='flex gap-1'>
                <HomeIcon className='w-4 h-auto' />
                {juice?.distillery?.name || "Unknown"}
              </div>

              <div className='flex gap-1'>
                <WifiIcon className='w-4 h-auto' />
                {juice.collectionCount || "Unknown"}
              </div>
            </div>
          </div>
        </Link>
        <CardMenu
          onEdit={() => setEditModalOpen(true)}
          onRemove={removeHandle}
        />
      </div>

      {isDeletModalOpen && (
        <ConfirmDeleteDialog
          open={isDeletModalOpen}
          onClose={() => setDeletModalOpen(false)}
          onConfirm={handleDeleteJuice}
          isLoading={deleteJuiceMutation.isLoading}
        />
      )}

      {isEditModalOpen && (
        <AddEditJuiceDrawer
          onClose={() => setEditModalOpen(false)}
          currentJuice={juice}
          onEditSuccess={onEditSuccessHandle}
        />
      )}
    </>
  );
};

const CardMenu = ({
  onEdit,
  onRemove,
}: {
  onEdit: VoidFunction;
  onRemove: VoidFunction;
}) => {
  return (
    <Menu as='div' className='absolute right-4 top-4 flex-shrink-0'>
      <Menu.Button className='w-6 h-6 p-1 flex justify-center items-center hover:bg-gray-100 rounded-md'>
        <FontAwesomeIcon icon={faEllipsis} className='w-4 h-auto' />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <Menu.Item>
            <button
              className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left'
              onClick={onEdit}
            >
              Edit
            </button>
          </Menu.Item>

          <Menu.Item>
            <button
              className='block px-4 py-2 text-sm text-gray-700 hover:bg-red-500 hover:text-white w-full text-left'
              onClick={onRemove}
            >
              Delete
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
