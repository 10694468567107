import { useState, useCallback, useEffect } from "react";
import {
  useGetUnassignedSubmissions,
  useGetMyAssignedSubmissions,
} from "../api/server/queries";
import { Submission, Juice, Distillery } from "../api/server/types";

import { Pagination } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SubmissionForm from "src/components/submissions/SubmissionForm";
import JuiceSearch from "src/components/submissions/JuiceSearch";
import SubmissionsNavTabs, {
  SubmissionsTab,
} from "src/components/submissions/SubmissionsNavTabs";
import SubmissionsList from "src/components/submissions/SubmissionsList";
import ImageModal from "src/components/submissions/ImageModal";
import DeleteModal from "src/components/submissions/DeleteModal";
import useError from "src/hooks/useError";
import useRefetchOnPageChange from "src/hooks/useRefetchOnPageChange";
import { useDebounce } from "use-debounce";

const itemsPerPage = 20;

export default function Submissions() {
  const [currentJuice, setCurrentJuice] = useState({} as Juice);
  const [mySubmissionsPage, setMySubmissionsPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [unassignedSubmissionsPage, setUnassignedSubmissionsPage] = useState(1);

  const [activeTab, setActiveTab] = useState<SubmissionsTab>(
    SubmissionsTab.MySubmissions
  );
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>("");
  const [imageModalIsOpen, setImageModalIsModalOpen] = useState(false);
  const [selectedSubmission, setSelectedSubmission] =
    useState<Submission | null>(null);
  const [submissionToDelete, setSubmissionToDelete] =
    useState<Submission | null>(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const {
    data: unassignedSubmissionsData,
    isLoading: unassignedSubmissionsLoading,
    refetch: refetchUnAssignedSubmissions,
    isRefetching: isRefetchingUnassignedSubmissions,
    error: unassignedSubmissionsError,
    remove: removeUnassignedSubmissionsError,
  } = useGetUnassignedSubmissions(
    debouncedSearchTerm,
    unassignedSubmissionsPage
  );
  const {
    data: mySubmissionsData,
    isLoading: mySubmissionsLoading,
    refetch: refecthMySubmissions,
    isRefetching: isRefetchingMySubmissions,
    error: mySubmissionsError,
    remove: removeMySubmissionsError,
  } = useGetMyAssignedSubmissions(mySubmissionsPage);
  useError(unassignedSubmissionsError, removeUnassignedSubmissionsError);
  useError(mySubmissionsError, removeMySubmissionsError);
  const unassignedSubmissions = unassignedSubmissionsData?.data;
  const mySubmissions = mySubmissionsData?.data;

  const unassignedSubmissionsIsRefetchingAfterPageChange =
    useRefetchOnPageChange(
      unassignedSubmissionsPage,
      refetchUnAssignedSubmissions,
      isRefetchingUnassignedSubmissions
    );
  const mySubmissionsIsRefetchingAfterPageChange = useRefetchOnPageChange(
    mySubmissionsPage,
    refecthMySubmissions,
    isRefetchingMySubmissions
  );
  const isRefetchingAfterPageChange =
    unassignedSubmissionsIsRefetchingAfterPageChange ||
    mySubmissionsIsRefetchingAfterPageChange;

  const submissionsLoading =
    unassignedSubmissionsLoading ||
    mySubmissionsLoading ||
    isRefetchingAfterPageChange;
  const page =
    activeTab === SubmissionsTab.MySubmissions
      ? mySubmissionsPage
      : unassignedSubmissionsPage;
  const pageCount =
    (activeTab === SubmissionsTab.MySubmissions
      ? mySubmissionsData?.total
      : unassignedSubmissionsData?.total) ?? 0;

  const refetchSubmissions = useCallback(async () => {
    try {
      await refetchUnAssignedSubmissions();
      await refecthMySubmissions();
    } catch (error) {
      console.error("Error refetching submissions:", error);
    }
  }, [refetchUnAssignedSubmissions, refecthMySubmissions]);

  const onChangeActiveTab = useCallback(
    (tab: SubmissionsTab) => setActiveTab(tab),
    []
  );

  const onSelectSubmission = useCallback(
    (submission?: Submission) => setSelectedSubmission(submission ?? null),
    []
  );

  const onToggleDeleteModalIsOpen = useCallback(
    (isOpen: boolean, submissionToDelete?: Submission) => {
      setDeleteModalIsOpen(isOpen);
      if (isOpen && submissionToDelete) {
        setSubmissionToDelete(submissionToDelete);
      } else if (!isOpen) {
        setSubmissionToDelete(null);
      }
    },
    []
  );

  const onToggleImageModalIsOpen = useCallback(
    (isOpen?: boolean) => setImageModalIsModalOpen((prev) => isOpen ?? !prev),
    []
  );

  const onOpenImage = useCallback((url: string) => {
    setSelectedImageUrl(url);
    setImageModalIsModalOpen(true);
  }, []);

  const onSelectJuice = useCallback(
    (juice: Juice) => setCurrentJuice(juice),
    []
  );

  const onAddDistilleryToJuice = useCallback(
    (distillery: Distillery | null) =>
      setCurrentJuice((prev) => ({
        ...prev,
        ...(distillery && { distillery }),
      })),
    []
  );

  const onChangePage = useCallback(
    (page: number) => {
      if (activeTab === SubmissionsTab.MySubmissions)
        return setMySubmissionsPage(page);
      if (activeTab === SubmissionsTab.Submissions)
        return setUnassignedSubmissionsPage(page);
    },
    [activeTab]
  );

  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value);
    setUnassignedSubmissionsPage(1); // Reset to first page when searching
  }, []);

  useEffect(() => {
    if (activeTab === SubmissionsTab.Submissions) {
      refetchUnAssignedSubmissions();
    }
  }, [debouncedSearchTerm, activeTab, refetchUnAssignedSubmissions]);

  return (
    <>
      <main className="lg:pl-96">
        <SubmissionForm
          onSelectSubmission={onSelectSubmission}
          refetchSubmissions={refetchSubmissions}
          selectedSubmission={selectedSubmission}
          onAddDistilleryToJuice={onAddDistilleryToJuice}
        />
        <JuiceSearch show={!selectedSubmission} onSelectJuice={onSelectJuice} />
      </main>

      <aside className="fixed inset-y-0 left-72 bottom-0 hidden w-96 border-r  lg:flex flex-col">
        <SubmissionsNavTabs
          activeTab={activeTab}
          onChangeActiveTab={onChangeActiveTab}
        />
        <SubmissionsList
          onToggleDeleteModalIsOpen={onToggleDeleteModalIsOpen}
          activeTab={activeTab}
          unassignedSubmissions={unassignedSubmissions}
          mySubmissions={mySubmissions}
          isLoading={submissionsLoading}
          onSelectSubmission={onSelectSubmission}
          onOpenImage={onOpenImage}
          refetchSubmissions={refetchSubmissions}
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
        />
        <Pagination
          count={Math.ceil(pageCount / itemsPerPage)}
          page={page}
          onChange={(event, value) => onChangePage(value)}
          className="flex justify-center items-center my-4"
        />
      </aside>
      <ImageModal
        imageUrl={selectedImageUrl}
        isOpen={imageModalIsOpen}
        onToggleIsOpen={onToggleImageModalIsOpen}
      />
      <DeleteModal
        submission={submissionToDelete}
        refetchSubmissions={refetchSubmissions}
        isOpen={deleteModalIsOpen}
        onToggleIsOpen={onToggleDeleteModalIsOpen}
      />
    </>
  );
}
