import { useParams } from "react-router-dom";
import { useGetJuice, useGetDistilleries } from "../api/server/queries";
import { useEffect, useState, Fragment } from "react";
import { Dialog, Combobox, Transition } from "@headlessui/react";
import { useUpdateJuiceMutation } from "../api/server/mutations";
import { Distillery, Bottle, Juice as JuiceType } from "../api/server/types";
import { rarityOptions } from "../utils/data";
import JuiceBottles from "../components/JuiceBottles";
import AddEditBottle from "../components/AddEditBottle";
import CircularProgress from "@mui/material/CircularProgress";
import { varietyOptions, releaseOptions } from "../utils/data";
import { toast } from "react-toastify";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const Juice = () => {
  const { id } = useParams<{ id: string }>();
  const [query, setQuery] = useState("");
  const { data: juice, isLoading, error, refetch } = useGetJuice(id ?? "");
  const { data: distilleries, refetch: refetchDistilleries } =
    useGetDistilleries(query, 1);
  const [isEditingJuice, setIsEditingJuice] = useState(false);
  const updateJuiceMutation = useUpdateJuiceMutation();
  const [currentJuice, setCurrentJuice] = useState<JuiceType>({} as JuiceType);
  const [isAddingBottle, setIsAddingBottle] = useState(false);
  const [isEditBottle, setIsEditBottle] = useState(false);
  const [currentBottle, setCurrentBottle] = useState({} as Bottle);
  const [selectedDistillery, setSelectedDistillery] =
    useState<Distillery | null>(juice?.distillery || null);

  const filteredDistilleries =
    query === ""
      ? distilleries ?? []
      : distilleries?.data.filter((distillery: Distillery) =>
          distillery.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        ) ?? [];

  useEffect(() => {
    if (juice) {
      setCurrentJuice(juice);
    }
  }, [juice]);

  useEffect(() => {
    if (juice?.distillery) {
      setSelectedDistillery(juice.distillery);
    }
  }, [juice]);

  useEffect(() => {
    const fetchDistilleries = async () => {
      await refetchDistilleries({ queryKey: ["distilleries", query, 1] });
    };

    fetchDistilleries();
  }, [query, refetchDistilleries]);

  const handleSaveEditedJuice = (e: any) => {
    e.preventDefault();
    updateJuiceMutation.mutate(currentJuice, {
      onSuccess: () => {
        toast.success("Juice updated successfully!");
        setIsEditingJuice(false);
        refetch();
      },
      onError: () => {
        toast.error("Error updating juice");
      },
    });
  };

  const handleAddingComplete = () => {
    setIsAddingBottle(false);
    setIsEditBottle(false);
    setCurrentBottle({} as Bottle);
    refetch();
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-start pt-32 min-h-screen">
        <CircularProgress />
      </div>
    );
  }

  return juice ? (
    <div>
      <div className="xl:pl-96">
        {isAddingBottle || isEditBottle ? (
          <AddEditBottle
            juice={juice}
            handleAddEditComplete={handleAddingComplete}
            isEditBottle={isEditBottle}
            currentBottle={currentBottle}
          />
        ) : (
          <JuiceBottles
            juice={juice}
            bottles={juice.sizes}
            setIsAddingBottle={setIsAddingBottle}
            setIsEditBottle={setIsEditBottle}
            setCurrentBottle={setCurrentBottle}
          />
        )}
      </div>
      <aside className="fixed inset-y-0 left-72 hidden w-96 overflow-y-auto border-r  xl:block">
        <div className="flex py-5 sm:py-5 items-center justify-center">
          <div className="ml-4 flex-shrink-0 sm:order-first sm:m-0">
            <img
              key={juice.imageUrl}
              src={juice.sizes[0]?.imageUrl}
              alt={juice.name}
              className="col-start-2 col-end-3 h-15 w-15 rounded-lg object-contain object-center sm:col-start-1 sm:row-span-2 sm:row-start-1 sm:h-40 sm:w-40 lg:h-40 lg:w-40"
            />
          </div>
        </div>
        <div>
          <dl>
            <div className="border-t  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-black">Name</dt>
              <dd className="mt-1 text-sm leading-6 text-black sm:col-span-2 sm:mt-0">
                {juice.name}
              </dd>
            </div>
            <div className="border-t  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-black">Variety</dt>
              <dd className="mt-1 text-sm leading-6 text-black sm:col-span-2 sm:mt-0">
                {juice.variety}
              </dd>
            </div>
            <div className="border-t  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-black">Proof</dt>
              <dd className="mt-1 text-sm leading-6 text-black sm:col-span-2 sm:mt-0">
                {juice.proof}
              </dd>
            </div>
            <div className="border-t  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-black">Age</dt>
              <dd className="mt-1 text-sm leading-6 text-black sm:col-span-2 sm:mt-0">
                {juice.age}
              </dd>
            </div>
            <div className="border-t  px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-black">Release</dt>
              <dd className="mt-1 text-sm leading-6 text-black sm:col-span-2 sm:mt-0">
                {juice.release}
              </dd>
            </div>
            <div className="border-t  p-4 flex justify-center items-center block">
              <button
                onClick={() => setIsEditingJuice(true)}
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80 h-9 px-4 py-2 w-full"
              >
                <FontAwesomeIcon icon={faPenToSquare} className="mr-3" />
                Edit Juice
              </button>
            </div>
          </dl>
        </div>
      </aside>
      <Transition.Root show={isEditingJuice} as={Fragment}>
        <Dialog as="div" className="relative z-100" onClose={setIsEditingJuice}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <Dialog.Title className="text-lg font-semibold mb-3">
                    Edit Juice
                  </Dialog.Title>
                  <form onSubmit={handleSaveEditedJuice} className="space-y-4">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-black/80 mb-1"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={currentJuice.name}
                          className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                          onChange={(e) =>
                            setCurrentJuice({
                              ...currentJuice,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-black/80 mb-1"
                        >
                          Proof
                        </label>
                        <input
                          type="number"
                          name="proof"
                          id="proof"
                          value={currentJuice.proof}
                          className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                          onChange={(e) =>
                            setCurrentJuice({
                              ...currentJuice,
                              proof: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                      <div className="sm:col-span-2 border-t border-gray-200 pt-5">
                        <h2 className="text-lg font-medium text-gray-900">
                          Age information
                        </h2>
                        <div className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                          <div className="sm:col-span-1 sm:col-start-1">
                            <label className="block text-sm font-medium text-gray-700">
                              Years old
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="age"
                                id="age"
                                value={currentJuice.age}
                                className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  setCurrentJuice({
                                    ...currentJuice,
                                    age: Number(e.target.value),
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-1">
                            <label className="block text-sm font-medium text-gray-700">
                              Months old
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="age"
                                id="age"
                                value={currentJuice.monthsOld}
                                className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  setCurrentJuice({
                                    ...currentJuice,
                                    monthsOld: Number(e.target.value),
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-1">
                            <label className="block text-sm font-medium text-gray-700">
                              Days old
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="age"
                                id="age"
                                value={currentJuice.daysOld}
                                className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                onChange={(e) =>
                                  setCurrentJuice({
                                    ...currentJuice,
                                    daysOld: Number(e.target.value),
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-2 border-t border-gray-200 pt-5">
                        <h2 className="text-lg font-medium text-gray-900">
                          Origin information
                        </h2>
                        <div className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"></div>
                        <label
                          htmlFor="origin"
                          className="block text-sm font-medium text-black/80 mb-1"
                        >
                          Origin
                        </label>
                        <input
                          type="text"
                          name="origin"
                          id="origin"
                          value={currentJuice.origin}
                          className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                          onChange={(e) =>
                            setCurrentJuice({
                              ...currentJuice,
                              origin: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="distillery"
                          className="block text-sm font-medium text-black/80 mb-1"
                        >
                          Distillery
                        </label>
                        <Combobox
                          value={selectedDistillery}
                          onChange={(distillery: Distillery | null) => {
                            setSelectedDistillery(distillery);
                            setCurrentJuice({
                              ...currentJuice,
                              distillery: distillery as Distillery,
                            });
                          }}
                        >
                          <div className="relative mt-1">
                            <Combobox.Input
                              className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                              displayValue={(distillery: Distillery | null) =>
                                distillery?.name || ""
                              }
                              onChange={(event) => setQuery(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}
                            >
                              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {Array.isArray(filteredDistilleries) &&
                                  filteredDistilleries.map(
                                    (distillery: Distillery) => (
                                      <Combobox.Option
                                        key={distillery.id}
                                        className={({ active }) =>
                                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active
                                              ? "bg-black/10 text-black"
                                              : "text-gray-900"
                                          }`
                                        }
                                        value={distillery}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={`block truncate ${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              }`}
                                            >
                                              {distillery.name}
                                            </span>
                                            {selected ? (
                                              <span
                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                  active
                                                    ? "text-white"
                                                    : "text-black"
                                                }`}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Combobox.Option>
                                    )
                                  )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                      <div className="sm:col-span-2 border-t border-gray-200 pt-5">
                        <h2 className="text-lg font-medium text-gray-900">
                          Other information
                        </h2>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="rarity"
                            className="block text-sm font-medium text-black/80 mb-2"
                          >
                            Rarity
                          </label>
                          <select
                            id="rarity"
                            name="rarity"
                            value={currentJuice.rarity}
                            onChange={(e) =>
                              setCurrentJuice({
                                ...currentJuice,
                                rarity: e.target.value,
                              })
                            }
                            className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                          >
                            <option value="" disabled>
                              Select a rarity
                            </option>
                            {rarityOptions.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="variety"
                              className="block text-sm font-medium text-black/80 mb-1"
                            >
                              Variety
                            </label>
                            <select
                              id="variety"
                              name="variety"
                              value={currentJuice.variety}
                              onChange={(e) =>
                                setCurrentJuice({
                                  ...currentJuice,
                                  variety: e.target.value,
                                })
                              }
                              className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                            >
                              {varietyOptions.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="release"
                              className="block text-sm font-medium text-black/80 mb-1"
                            >
                              Release
                            </label>
                            <select
                              id="release"
                              name="release"
                              value={currentJuice.release}
                              onChange={(e) =>
                                setCurrentJuice({
                                  ...currentJuice,
                                  release: e.target.value,
                                })
                              }
                              className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                            >
                              {releaseOptions.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <fieldset>
                          <div className="relative flex items-start pt-4">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                              <label className="select-none font-medium text-gray-900">
                                Pickable
                              </label>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                              <input
                                type="checkbox"
                                checked={currentJuice?.pickable}
                                onChange={(e) => {
                                  setCurrentJuice({
                                    ...currentJuice,
                                    pickable: e.target.checked,
                                  });
                                }}
                                className="h-4 w-4 rounded border text-black focus:ring-black"
                              />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="pt-5">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-black mb-3 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black sm:col-start-2"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setIsEditingJuice(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Juice;
