import { useState } from "react";
import { Bottle, Juice } from "../api/server/types";
import {
  useCreateBottleMutation,
  useDeleteBottleMutation,
  useUpdateBottleMutation,
} from "../api/server/mutations";
import { sizeOptions } from "../utils/data";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

const AddEditBottle = ({
  juice,
  handleAddEditComplete,
  isEditBottle,
  currentBottle,
}: {
  juice: Juice;
  handleAddEditComplete: any;
  isEditBottle: any;
  currentBottle: Bottle;
}) => {
  const defaultBottle = { size: 750, rarity: "common", juiceId: juice.id };
  const [bottle, setBottle] = useState<Bottle>(
    Object.keys(currentBottle).length > 1
      ? currentBottle
      : (defaultBottle as Bottle),
  );
  const createBottleMutation = useCreateBottleMutation();
  const editBottleMutation = useUpdateBottleMutation();
  const deleteBottleMutation = useDeleteBottleMutation();
  const [imageUrl, setImageUrl] = useState<string>(bottle?.imageUrl || "");
  const [isDeletingBottle, setIsDeletingBottle] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const people = [
    { key: "age_days", value: "Age (Days)" },
    { key: "age_months", value: "Age (Months)" },
    { key: "age_years", value: "Age (Years)" },
    { key: "batch", value: "Batch" },
    { key: "barrel_number", value: "Barrel Number" },
    { key: "bottled_by", value: "Bottled By" },
    { key: "bottle_date", value: "Bottle Date" },
    { key: "bottle_number", value: "Bottle Number" },
    { key: "distillation_date", value: "Distillation Date" },
    { key: "dumped", value: "Dumped" },
    { key: "finish_age", value: "Finish Age" },
    { key: "finish_type", value: "Finish Type" },
    { key: "floor", value: "Floor" },
    { key: "location", value: "Location" },
    { key: "proof", value: "Proof" },
    { key: "recipe_options", value: "Recipe Options" },
    { key: "recipe", value: "Recipe" },
    { key: "rick", value: "Rick" },
    { key: "rickhouse", value: "Rickhouse" },
    { key: "season", value: "Season" },
    { key: "stave_profile", value: "Stave Profile" },
    { key: "staves", value: "Staves" },
  ];
  
  const handleSave = () => {
    const bottleToSend = {
      ...bottle,
      optionals: selectedOptions,
    }

    if (isEditBottle) {
      editBottleMutation.mutate({
        ...bottleToSend,
        optionals: bottleToSend.optionals,
      }, {
        onSuccess: () => {
          toast.success("Bottle updated successfully!");
          handleAddEditComplete();
        },
        onError: () => {
          toast.error("Error updating bottle");
        },
      });
    } else {
      createBottleMutation.mutate(bottleToSend, {
        onSuccess: () => {
          toast.success("Bottle created successfully!");
          handleAddEditComplete();
        },
        onError: () => {
          toast.error("Error creating bottle");
        },
      });
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const { base64 } = await toBase64(file);
      setBottle({ ...bottle, image: base64 });
      setImageUrl(base64);
    }
  };

  const toBase64 = (file: File) =>
    new Promise<{ base64: string }>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        resolve({ base64 });
      };
      reader.onerror = (error) => reject(error);
    });

  const handleDelete = () => {
    deleteBottleMutation.mutate(bottle.id, {
      onSuccess: () => {
        toast.success("Bottle deleted successfully!");
        handleAddEditComplete();
      },
      onError: () => {
        toast.error("Error deleting bottle");
      },
    });
  };

  return (
    <>
      <div className="mx-auto p-6">
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-800">
            {isEditBottle ? `Edit ${juice.name}` : "Add Bottle"}
          </h1>
          <p className="text-gray-600">
            Please make sure all information entered is accurate.

          </p>
        </div>
        <div className="mb-6">
          <img
            key={juice.imageUrl}
            src={imageUrl}
            alt={juice.name}
            className="mx-auto h-24 w-24 object-cover rounded-full mb-4"
          />
          <div className="flex justify-center items-center">
            <input
              type="file"
              accept=".png, .jpeg, .gif"
              className="form-input text-sm text-black/50
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-md file:border-0
                        file:text-sm file:font-semibold
                        file:bg-black/10 file:text-black/70
                        hover:file:bg-black/20"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label
              htmlFor="price"
              className="block text-sm font-medium text-black/80 mb-2"
            >
              Price
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={bottle.price}
              onChange={(e) =>
                setBottle({ ...bottle, price: Number(e.target.value) })
              }
              className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
            />
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="secondary"
              className="block text-sm font-medium text-black/80 mb-2"
            >
              Secondary Lower Bound
            </label>
            <div className="mt-2">
              <input
                type="number"
                id="secondary"
                name="secondary"
                value={bottle.secondary}
                onChange={(e) =>
                  setBottle({ ...bottle, secondary: Number(e.target.value) })
                }
                className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="secondaryOuterBound"
              className="block text-sm font-medium text-black/80 mb-2"
            >
              Secondary Upper Bound
            </label>
            <div className="mt-2">
              <input
                type="number"
                id="secondaryOuterBound"
                name="secondaryOuterBound"
                value={bottle.secondaryOuterBound}
                onChange={(e) =>
                  setBottle({
                    ...bottle,
                    secondaryOuterBound: Number(e.target.value),
                  })
                }
                className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label
              htmlFor="size"
              className="block text-sm font-medium text-black/80 mb-2"
            >
              Size (in mL)
            </label>
            <select
              id="size"
              name="size"
              value={bottle.size}
              onChange={(e) =>
                setBottle({ ...bottle, size: Number(e.target.value) })
              }
              className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
            >
              <option value="" disabled>
                Select a size
              </option>
              {sizeOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="sm:col-span-6">
            <label
              htmlFor="sku"
              className="block text-sm font-medium text-black/80 mb-2"
            >
              SKU
            </label>
            <input
              type="text"
              id="sku"
              name="sku"
              value={bottle.sku}
              onChange={(e) => setBottle({ ...bottle, sku: e.target.value })}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
            />
          </div>
          <fieldset>
            <legend className="text-base font-semibold leading-6 text-gray-900">Single barrel attributes</legend>
            <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
              {people.map((person, personIdx) => (
                <div key={personIdx} className="relative flex items-start py-4">
                  <div className="min-w-0 flex-1 text-sm leading-6">
                    <label htmlFor={`person-${person.key}`} className="select-none font-medium text-gray-900">
                      {person.value}
                    </label>
                  </div>
                  <div className="ml-3 flex h-6 items-center">
                    <input
                      id={`person-${person.key}`}
                      name={`person-${person.key}`}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-black focus:ring-black"
                      checked={selectedOptions.includes(person.key) || bottle.optionals?.includes(person.key)}
                      onChange={(e) => {
                        const option = person.key;
                        if (e.target.checked) {
                          setSelectedOptions((prevOptions) => [...prevOptions, option]);
                        } else {
                          setSelectedOptions((prevOptions) =>
                            prevOptions.filter((currentOption) => currentOption !== option)
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={handleSave}
            className="mt-3 inline-flex w-full justify-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-black hover:bg-black/80 sm:col-start-1 sm:mt-0"
          >
            {editBottleMutation.isLoading || createBottleMutation.isLoading ? (
              <ClipLoader color="#000000" size={20} />
            ) : (
              "Save"
            )}
          </button>
          <button
            onClick={handleAddEditComplete}
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          >
            Cancel
          </button>
        </div>
        {isEditBottle && (
          <div className="mt-2">
            <button
              onClick={() => setIsDeletingBottle(true)}
              className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-500 hover:bg-red-600 sm:col-start-1 sm:mt-0"
            >
              Delete Bottle
            </button>
          </div>
        )}
      </div>
      <ConfirmDeleteDialog
        open={isDeletingBottle}
        onClose={() => setIsDeletingBottle(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default AddEditBottle;
