/* eslint-disable @typescript-eslint/no-explicit-any */
import { camelCase, snakeCase } from 'lodash';
import { Pipe, Objects } from 'hotscript';

type CamelCase<T> = Pipe<T, [Objects.CamelCaseDeep]>;
// type SnakeCase<T> = Pipe<T, [Objects.SnakeCaseDeep]>;

export function keysToCamel<T = unknown>(o: T): CamelCase<T> {
  const isObjectType = (obj: any): obj is object =>
    typeof obj === 'object' && obj !== null && !Array.isArray(obj);

  if (isObjectType(o)) {
    const n: any = {} as CamelCase<T>;
    (Object.keys(o) as Array<Extract<keyof T, string>>).forEach((k) => {
      n[camelCase(k)] = keysToCamel((o as Record<string, unknown>)[k]);
    });

    return n;
  } else if (Array.isArray(o)) {
    return (o as any[]).map((i) => {
      return keysToCamel(i);
    }) as CamelCase<T>;
  }

  return o as CamelCase<T>;
}

export function keysToSnake<T = unknown>(o: T): T {
  const isObjectType = (obj: any): obj is object =>
    typeof obj === 'object' && obj !== null && !Array.isArray(obj);

  if (isObjectType(o)) {
    const n: any = {} as T;
    (Object.keys(o) as Array<Extract<keyof T, string>>).forEach((k) => {
      const value = (o as Record<string, unknown>)[k];
      if (isObjectType(value) || Array.isArray(value)) {
        n[snakeCase(k)] = keysToSnake(value);
      } else {
        n[snakeCase(k)] = value;
      }
    });

    return n;
  } else if (Array.isArray(o)) {
    return (o as any[]).map((i) => {
      return keysToSnake(i);
    }) as T;
  }

  return o as T;
}
