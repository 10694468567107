import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { jwtDecode } from "jwt-decode";

type AccountType = "admin" | "editor" | "user" | "store" | "distillery";

interface JwtPayload {
  acc: string;
}

interface AuthContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  accessToken: string | null;
  setAccessToken: Dispatch<SetStateAction<string | null>>;
  accountType: AccountType | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [accountType, setAccountType] = useState<AccountType | null>(null);

  // On any updates to the access token check account type to make sure it hasn't changed
  useEffect(() => {
    if (accessToken) {
      const decoded = jwtDecode(accessToken) as JwtPayload;

      setAccountType(decoded.acc as AccountType);
    }
  }, [accessToken]);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("accessToken");
    setIsLoggedIn(!!storedAccessToken);
    setAccessToken(storedAccessToken);

    const unauthorizedHandler = () => {
      setIsLoggedIn(false);
      setAccessToken(null);
    };
    window.addEventListener("unauthorized", unauthorizedHandler);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("unauthorized", unauthorizedHandler);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        accessToken,
        setAccessToken,
        accountType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
