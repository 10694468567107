import { Juice } from "../api/server/types";
import { Link } from "react-router-dom";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useState } from "react";

const JuiceCard = ({
  juice,
  editJuice,
  deleteJuice,
  linkToJuicePage = true,
  onClick,
  showActions = true,
}: {
  juice: Juice;
  editJuice?: (juice: Juice) => void;
  deleteJuice?: (id: string) => void;
  linkToJuicePage?: boolean;
  onClick?: () => any;
  showActions?: boolean;
}) => {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const totalBottles = juice.sizes.length;

  const handleEdit = () => {
    if (editJuice) {
      editJuice(juice);
    }
  };

  const handleDelete = () => {
    if (deleteJuice) {
      setIsConfirmingDelete(true);
    }
  };

  const CardContent = (
    <>
      <div className="flex min-w-0 gap-x-4">
        <img
          src={juice?.imageUrl}
          alt={juice.name}
          className="h-12 w-12 flex-none rounded-full bg-white object-contain"
        />
        <div className="min-w-0 flex-auto">
          <h2 className="text-sm font-semibold leading-6 text-black">
            {juice.name}
          </h2>
          <span className="inline-flex items-center rounded-md bg-black/10 px-2 py-1 text-xs font-medium text-black">
            {totalBottles} {totalBottles > 1 ? "bottles" : "bottle"}
          </span>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        {showActions && (
          <>
            <button
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleEdit();
              }}
              className="hidden rounded-md bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm hover:bg-gray-50 sm:block"
            >
              Edit
            </button>
            <button
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setIsConfirmingDelete(true);
              }}
              className="rounded bg-red-600 px-2.5 py-1.5 text-xs font-semibold text-red-50 shadow-sm hover:bg-red-800"
            >
              Delete
            </button>
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      {linkToJuicePage ? (
        <Link
          to={`/juices/${juice.id}`}
          className="border-t relative flex justify-between gap-x-6 px-4 py-5 hover:black sm:px-6 lg:px-8"
        >
          {CardContent}
        </Link>
      ) : (
        <div
          onClick={onClick}
          className="border-t relative flex justify-between gap-x-6 px-4 py-5 hover:bg-black/10 sm:px-6 lg:px-8"
        >
          {CardContent}
        </div>
      )}
      {deleteJuice && (
        <ConfirmDeleteDialog
          open={isConfirmingDelete}
          onClose={() => setIsConfirmingDelete(false)}
          onConfirm={() => deleteJuice(juice.id)}
        />
      )}
    </>
  );
};

export default JuiceCard;
