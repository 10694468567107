import React, { useState, FormEvent } from "react";
import { useAuth } from "../context/AuthContext";
import { useSignInMutation } from "../api/authServer/mutations";
import icon from "../assets/icon.png";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { AuthResponse } from "../api/authServer/types";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setIsLoggedIn, setAccessToken } = useAuth();
  const useSignIn = useSignInMutation();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    useSignIn.mutate(
      { identifier: username, password },
      {
        onSuccess: (data: AuthResponse) => {
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          setAccessToken(data.accessToken);
          setIsLoggedIn(true);
        },
        onError: (error) => {
          toast.error("Invalid credentials");
        },
      }
    );
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-24 w-auto" src={icon} alt="OnlyDrams" />
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-black">
          ODIS
        </h2>
        <p className="text-center leading-9 tracking-tight text-black">
          An OnlyDrams Internal Service
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black focus-visible:ring-black sm:text-sm sm:leading-6 p-3"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 p-3"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
            >
              {useSignIn.isLoading ? (
                <ClipLoader color="#000000" size={20} />
              ) : (
                "Sign in"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
