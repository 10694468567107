import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { Dialog, Transition, Switch } from "@headlessui/react";
import React, { useState, useEffect, Fragment } from "react";
import { useGetCategories } from "src/api/server/queries";
import { Category } from "src/api/server/types";
import { cn } from "src/utils/utils";
import { toast } from "react-toastify";
import {
  useAddCategoryMutation,
  useUpdateCategoryMutation,
} from "src/api/server/mutations";

export default function Example() {
  const addCategory = useAddCategoryMutation();
  const updateCategory = useUpdateCategoryMutation();
  const [categories, setCategories] = useState([] as Category[]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  let [isOpen, setIsOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [modalMode, setModalMode] = useState<"add" | "edit">("add");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data, isLoading, refetch } = useGetCategories(currentPage);

  const totalPages = Math.ceil(data?.total / pageSize);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refetch({ queryKey: ["categories", { page }] });
  };

  useEffect(() => {
    if (
      data?.data &&
      JSON.stringify(data.data) !== JSON.stringify(categories)
    ) {
      setCategories(data.data);
    }
  }, [data?.data, categories]);

  const openModal = (mode: "add" | "edit", category?: Category) => {
    setModalMode(mode);
    setSelectedCategory(category || null);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedCategory(null);
    setIsOpen(false);
  };

  return (
    <div>
      <div className="sm:flex sm:items-center border-b pb-5">
        <div className="sm:flex-auto px-5 sm:px-5 lg:px-8 pt-5">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Category management
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all current categories in OnlyDrams.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none pr-8 pt-5">
          <button
            type="button"
            onClick={() => openModal("add")}
            className="block rounded-md bg-green-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
          >
            Add Category
          </button>
        </div>
      </div>
      <div className="px-5 sm:px-5 lg:px-8">
        <div className="-mx-4 m-10 ring-1 ring-gray-200 sm:mx-0 sm:rounded-lg mb-15">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Juice count
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Status
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Select</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category.name}>
                  <td
                    className={cn(
                      "relative py-4 pl-4 pr-3 text-sm sm:pl-6 border-t"
                    )}
                  >
                    <div className="font-medium text-gray-900">
                      {category.name}
                    </div>
                  </td>
                  <td
                    className={cn(
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell border-t"
                    )}
                  >
                    {category.count}
                  </td>
                  <td
                    className={cn(
                      "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell border-t"
                    )}
                  >
                    {category.status === "active" ? (
                      <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                        <svg
                          className="h-1.5 w-1.5 fill-green-500"
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                        >
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        Active
                      </span>
                    ) : (
                      <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                        <svg
                          className="h-1.5 w-1.5 fill-red-500"
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                        >
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        Inactive
                      </span>
                    )}
                  </td>
                  <td
                    className={cn(
                      "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 border-t"
                    )}
                  >
                    <button
                      type="button"
                      onClick={() => openModal("edit", category)}
                      className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Edit<span className="sr-only">, {category.name}</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-md border border-gray-50 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-50 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * pageSize + 1}
                  </span>{" "}
                  to <span className="font-medium">{25}</span> of{" "}
                  <span className="font-medium">{data?.total}</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {Array.from(
                    { length: totalPages },
                    (_, index) => index + 1
                  ).map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      aria-current={currentPage === page ? "page" : undefined}
                      className={cn(
                        currentPage === page
                          ? "relative z-10 inline-flex items-center bg-black px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                          : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0",
                        "hidden md:inline-flex"
                      )}
                    >
                      {page}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-100"
          open={isOpen}
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-100 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {modalMode === "add"
                          ? "Add Category"
                          : `Edit ${selectedCategory?.name}`}
                      </Dialog.Title>
                      <div className="mt-2">
                        <form>
                          <div>
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Category Name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                placeholder="Enter category name"
                                value={selectedCategory?.name || ""}
                                onChange={(e) =>
                                  setSelectedCategory({
                                    ...selectedCategory,
                                    name: e.target.value,
                                  } as Category)
                                }
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <Switch.Group
                              as="div"
                              className="flex items-center justify-between"
                            >
                              <span className="flex flex-grow flex-col">
                                <Switch.Label
                                  as="span"
                                  className="text-sm font-medium leading-6 text-gray-900"
                                  passive
                                >
                                  Active
                                </Switch.Label>
                                <Switch.Description
                                  as="span"
                                  className="text-sm text-gray-500"
                                >
                                  Whether the category is active or not.
                                </Switch.Description>
                              </span>
                              <Switch
                                checked={
                                  modalMode === "edit"
                                    ? selectedCategory?.status === "active"
                                    : enabled
                                }
                                onChange={(checked) => {
                                  if (modalMode === "edit") {
                                    setSelectedCategory({
                                      ...selectedCategory,
                                      status: checked ? "active" : "inactive",
                                    } as Category);
                                  } else {
                                    setEnabled(checked);
                                  }
                                }}
                                className={cn(
                                  modalMode === "edit"
                                    ? selectedCategory?.status === "active"
                                      ? "bg-black"
                                      : "bg-gray-200"
                                    : enabled
                                    ? "bg-black"
                                    : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={cn(
                                    modalMode === "edit"
                                      ? selectedCategory?.status === "active"
                                        ? "translate-x-5"
                                        : "translate-x-0"
                                      : enabled
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </Switch.Group>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-green-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-900"
                      onClick={async () => {
                        try {
                          if (modalMode === "add") {
                            const newCategory = {
                              name: selectedCategory?.name || "",
                              live: enabled ? true : false,
                            };
                            await addCategory.mutateAsync(newCategory);
                            toast.success("Category added successfully.");
                          } else if (modalMode === "edit" && selectedCategory) {
                            await updateCategory.mutateAsync({
                              id: selectedCategory.id,
                              name: selectedCategory.name,
                              live:
                                selectedCategory.status === "active"
                                  ? true
                                  : false,
                            });
                            toast.success("Category updated successfully.");
                          }
                          refetch();
                          setIsOpen(false);
                        } catch (error) {
                          console.error("Error updating category:", error);
                          toast.error(
                            "An error occurred while updating the category."
                          );
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
