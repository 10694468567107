import { useMutation } from "react-query";
import {
  deleteSubmission,
  deleteUpc,
  addUPC,
  updateDistillery,
  updateJuice,
  deleteJuice,
  createJuice,
  createBottle,
  updateBottle,
  createDistillery,
  updateBarcode,
  deleteBottle,
  deleteDistillery,
  assignToSelf,
  confirmUser,
  addInventory,
  addCategory,
  updateCategory,
  completeSubmission,
  saveEntry,
  addToCollection,
  saveRejectReason,
} from "./requests";
import {
  Bottle,
  CreateDistilleryType,
  Distillery,
  Juice,
  Entry,
} from "./types";

export const useUpdateDistilleryMutation = () => {
  return useMutation({
    mutationFn: ({ id, distillery }: { id: string; distillery: Distillery }) =>
      updateDistillery(id, distillery),
  });
};

export const useUpdateJuiceMutation = () => {
  return useMutation({
    mutationFn: (juice: Juice) => updateJuice(juice),
  });
};

export const useDeleteJuiceMutation = () => {
  return useMutation({
    mutationFn: (juiceId: string) => deleteJuice(juiceId),
  });
};

export const useCreateJuiceMutation = () => {
  return useMutation({
    mutationFn: (juice: Juice) => createJuice(juice),
  });
};

export const useCreateBottleMutation = () => {
  return useMutation({
    mutationFn: (bottle: Bottle) => createBottle(bottle),
  });
};

export const useUpdateBottleMutation = () => {
  return useMutation({
    mutationFn: (bottle: Bottle) => updateBottle(bottle),
  });
};

export const useCreateDistilleryMutation = () => {
  return useMutation({
    mutationFn: (distillery: CreateDistilleryType) =>
      createDistillery(distillery),
  });
};

export const useAddUPCMutation = () => {
  return useMutation({
    mutationFn: ({ bottleId, upc }: { bottleId: string; upc: string }) =>
      addUPC(bottleId, upc),
  });
};

export const useDeleteSubmissionMutation = () => {
  return useMutation({
    mutationFn: ({ code }: { code: string }) => deleteSubmission(code),
  });
};

export const useCompleteSubmissionMutation = () => {
  return useMutation({
    mutationFn: ({ code }: { code: string }) => completeSubmission(code),
  });
};

export const useAssignToSelfMutation = () => {
  return useMutation({
    mutationFn: ({ code }: { code: string }) => assignToSelf(code),
  });
};

export const useUpdateBarcodeMutation = () => {
  return useMutation({
    mutationFn: ({ upc, note }: { upc: string; note: string }) =>
      updateBarcode(upc, note),
  });
};

export const useDeleteBottleMutation = () => {
  return useMutation({
    mutationFn: (bottleId: string) => deleteBottle(bottleId),
  });
};

export const useDeleteDistilleryMutation = () => {
  return useMutation({
    mutationFn: (distilleryId: string) => deleteDistillery(distilleryId),
  });
};

export const useDeleteUpcMutation = () => {
  return useMutation({
    mutationFn: ({ bottleId, code }: { bottleId: string; code: string }) =>
      deleteUpc(bottleId, code),
  });
};

export const useConfirmUserMutation = () => {
  return useMutation({
    mutationFn: (userId: string) => confirmUser(userId),
    onError: (error: any) => {
      console.error("Error in confirmUser mutation:", error);
    },
  });
};

export const useAddToCollectionMutation = () => {
  return useMutation({
    mutationFn: ({ userId, bottleId }: { userId: string; bottleId: string }) =>
      addToCollection(userId, bottleId),
  });
};

export const useAddInventoryMutation = () => {
  return useMutation({
    mutationFn: ({
      productId,
      storeId,
      quantity,
      unlimited,
    }: {
      storeId: string;
      productId: string;
      quantity: number;
      unlimited: boolean;
    }) => addInventory(productId, storeId, quantity, unlimited),
  });
};

export const useAddCategoryMutation = () => {
  return useMutation({
    mutationFn: ({ name, live }: { name: string; live: boolean }) =>
      addCategory(name, live),
  });
};

export const useUpdateCategoryMutation = () => {
  return useMutation({
    mutationFn: ({
      id,
      name,
      live,
    }: {
      id: string;
      name: string;
      live: boolean;
    }) => updateCategory(id, name, live),
  });
};

export const useSaveEntryMutation = () => {
  return useMutation({
    mutationFn: ({ entry }: { entry: Entry }) => saveEntry(entry),
  });
};

export const useSaveRejectReasonMutation = () => {
  return useMutation({
    mutationFn: ({ code, reason }: { code: string; reason: string }) =>
      saveRejectReason(code, reason),
  });
};
