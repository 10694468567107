import React, { createContext, useState, useContext, ReactNode } from 'react';

const ProductsContext = createContext<ProductsContextType | undefined>(undefined);

interface ProductsState {
  distilleries: any[];
  totalCount: number;
}

interface ProductsContextType {
  productsState: ProductsState;
  setProductsState: React.Dispatch<React.SetStateAction<ProductsState>>;
}

interface ProductsProviderProps {
  children: ReactNode;
}

export const ProductsProvider: React.FC<ProductsProviderProps> = ({ children }) => {
  const [productsState, setProductsState] = useState<ProductsState>({
    distilleries: [],
    totalCount: 0,
  });

  const value = { productsState, setProductsState };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = (): ProductsContextType => {
  const context = useContext(ProductsContext);
  if (context === undefined) {
    throw new Error('useProducts must be used within a ProductsProvider');
  }
  return context;
};
