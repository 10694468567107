import { Dialog, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import React, { Fragment, useState } from "react";
import { ClipLoader } from "react-spinners";

interface ConfirmDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmationText?: string;
  subText?: string;
  isLoading?: boolean;
}

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
  open,
  onClose,
  onConfirm,
  confirmationText,
  isLoading,
  subText,
}) => {
  const [userConfirmationText, setUserConfirmationText] = useState<string>("");
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        open={open}
        onClose={onClose}
        className='fixed z-100 inset-0 overflow-y-auto'
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all p-6 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
            <div className='flex flex-col gap-4'>
              {" "}
              <div className='space-y-2'>
                <Dialog.Title className='text-lg font-semibold'>
                  {confirmationText
                    ? `Delete ${confirmationText}?`
                    : "Are you absolutely sure you want to delete this item?"}
                </Dialog.Title>
                <p className='text-sm text-black/60'>
                  {confirmationText
                    ? `To confirm, type "${confirmationText}" below`
                    : "Are you sure you want to delete this item? This action cannot be undone and has the potential to cause app issues."}
                </p>
              </div>
              {confirmationText && (
                <input
                  type='text'
                  value={userConfirmationText}
                  onChange={e => setUserConfirmationText(e.target.value)}
                  className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-red-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6'
                />
              )}
              <div className='flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2'>
                {" "}
                {/* This now has spacing applied from the parent */}
                <button
                  type='button'
                  onClick={onClose}
                  className='inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 mt-2 sm:mt-0'
                >
                  Cancel
                </button>
                <button
                  type='button'
                  onClick={onConfirm}
                  disabled={
                    confirmationText
                      ? userConfirmationText !== confirmationText
                      : false
                  }
                  className={clsx(
                    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90 h-9 px-4 py-2",
                    {
                      "bg-gray-400 cursor-not-allowed":
                        confirmationText &&
                        userConfirmationText !== confirmationText,
                      "bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500":
                        !confirmationText,
                      "!text-transparent": isLoading,
                    }
                  )}
                >
                  {isLoading ? (
                    <ClipLoader
                      className='absolute'
                      color='#ffffff'
                      size={20}
                    />
                  ) : (
                    ""
                  )}
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmDeleteDialog;
