import { Bottle, Distillery, Juice } from "../api/server/types";
import { useGetDistilleries } from "../api/server/queries";
import { useEffect, useState, Fragment, FormEvent } from "react";
import { rarityOptions } from "../utils/data";

import { varietyOptions, releaseOptions } from "../utils/data";
import { Dialog, Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  useCreateJuiceMutation,
  useUpdateJuiceMutation,
} from "src/api/server/mutations";

import { useDebounce } from "use-debounce";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

export const AddEditJuiceDrawer = ({
  onClose,
  isNewJuice,
  currentJuice,
  onCreateSuccess,
  onEditSuccess,
}: {
  onClose: () => void;
  isNewJuice?: boolean;
  currentJuice: Juice;
  onCreateSuccess?: VoidFunction;
  onEditSuccess?: VoidFunction;
}) => {
  const [data, setData] = useState(currentJuice);
  const [query, setQuery] = useState("");
  const [debouncedQueryTerm] = useDebounce(query, 500);

  const { data: distilleries, refetch: refetchDistilleries } =
    useGetDistilleries(debouncedQueryTerm, 1);

  const [selectedDistillery, setSelectedDistillery] = useState<Distillery>(
    currentJuice?.distillery
  );

  useEffect(() => {
    debouncedQueryTerm &&
      refetchDistilleries({
        queryKey: ["distilleries", debouncedQueryTerm, 1],
      });
  }, [debouncedQueryTerm, refetchDistilleries]);

  const filteredDistilleries =
    query === ""
      ? distilleries ?? []
      : distilleries?.data.filter((distillery: Distillery) =>
          distillery.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        ) ?? [];

  const createJuiceMutation = useCreateJuiceMutation();
  const updateJuiceMutation = useUpdateJuiceMutation();

  const onSubmitHandle = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNewJuice) {
      createJuiceMutation.mutate(data, {
        onSuccess: () => {
          onClose();
          onCreateSuccess && onCreateSuccess();
        },
        onError: () => {
          toast.error("Error adding Juice");
        },
      });
    } else {
      updateJuiceMutation.mutate(data, {
        onSuccess: () => {
          onClose();
          onEditSuccess && onEditSuccess();
        },
        onError: () => {
          toast.error("Error editing Juice");
        },
      });
    }
  };

  return (
    <Transition
      show
      appear
      as={Fragment}
      enter='ease-out duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100 '
      leave='ease-in duration-200'
      leaveFrom='opacity-100 '
      leaveTo='opacity-0 '
    >
      <Dialog as='div' className='relative z-100' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter='transition ease duration-300 transform'
          enterFrom='translate-x-full'
          enterTo='translate-x-0'
          leave='transition ease duration-300 transform'
          leaveFrom='translate-x-0'
          leaveTo='translate-x-full'
        >
          <Dialog.Panel className='bg-white fixed top-0 right-0 max-w-sm w-full h-screen overflow-y-auto p-6'>
            <Dialog.Title className='text-lg font-semibold mb-3'>
              {isNewJuice ? "Add" : "Edit"} Juice
            </Dialog.Title>
            <form onSubmit={onSubmitHandle} className='space-y-4'>
              <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
                {!isNewJuice && (
                  <div className='flex gap-4 sm:col-span-2'>
                    {currentJuice?.imageUrl ? (
                      <img
                        key={currentJuice?.imageUrl}
                        src={currentJuice?.imageUrl}
                        alt={currentJuice?.name}
                        width={96}
                        height={96}
                        className='rounded-lg object-contain object-center w-24 h-24 filter drop-shadow-lg'
                      />
                    ) : (
                      <div className='rounded-lg w-24 h-24 bg-gray-300' />
                    )}
                    <div className='flex flex-col pt-4'>
                      <p className='text-lg font-semibold'>
                        {currentJuice.name}
                      </p>
                      <BottleSizesView data={currentJuice.sizes} />
                    </div>
                  </div>
                )}
                <div className='sm:col-span-2'>
                  <label
                    htmlFor='name'
                    className='block text-sm font-medium text-black/80 mb-1'
                  >
                    Name
                  </label>
                  <input
                    type='text'
                    name='name'
                    id='name'
                    value={data.name}
                    className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                    onChange={e =>
                      setData({
                        ...data,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='sm:col-span-2'>
                  <label
                    htmlFor='name'
                    className='block text-sm font-medium text-black/80 mb-1'
                  >
                    Proof
                  </label>
                  <input
                    type='number'
                    name='proof'
                    id='proof'
                    value={data.proof}
                    className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                    onChange={e =>
                      setData({
                        ...data,
                        proof: Number(e.target.value),
                      })
                    }
                  />
                </div>
                <div className='sm:col-span-2 border-t border-gray-200 pt-5'>
                  <h2 className='text-sm text-fog'>
                    Age information
                  </h2>
                  <div className='mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4'>
                    <div className='sm:col-span-1 sm:col-start-1'>
                      <label className='block text-sm font-medium text-gray-700'>
                        Years old
                      </label>
                      <div className='mt-1'>
                        <input
                          type='number'
                          name='age'
                          id='age'
                          value={data.yearsOld}
                          className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                          onChange={e =>
                            setData({
                              ...data,
                              yearsOld: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='sm:col-span-1'>
                      <label className='block text-sm font-medium text-gray-700'>
                        Months old
                      </label>
                      <div className='mt-1'>
                        <input
                          type='number'
                          name='age'
                          id='age'
                          value={data.monthsOld}
                          className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                          onChange={e =>
                            setData({
                              ...data,
                              monthsOld: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='sm:col-span-1'>
                      <label className='block text-sm font-medium text-gray-700'>
                        Days old
                      </label>
                      <div className='mt-1'>
                        <input
                          type='number'
                          name='age'
                          id='age'
                          value={data.daysOld}
                          className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                          onChange={e =>
                            setData({
                              ...data,
                              daysOld: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='sm:col-span-2 border-t border-gray-200 pt-5'>
                  <h2 className='text-sm text-fog'>
                    Origin information
                  </h2>
                  <div className='mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4'></div>
                  <label
                    htmlFor='origin'
                    className='block text-sm font-medium text-black/80 mb-1'
                  >
                    Origin
                  </label>
                  <input
                    type='text'
                    name='origin'
                    id='origin'
                    value={data.origin}
                    className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                    onChange={e =>
                      setData({
                        ...data,
                        origin: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='sm:col-span-2'>
                  <label
                    htmlFor='distillery'
                    className='block text-sm font-medium text-black/80 mb-1'
                  >
                    Distillery
                  </label>
                  <Combobox
                    value={selectedDistillery}
                    onChange={(distillery: Distillery) => {
                      setSelectedDistillery(distillery);
                      setData({
                        ...data,
                        distillery: distillery as Distillery,
                      });
                    }}
                  >
                    <div className='relative mt-1'>
                      <Combobox.Input
                        className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                        displayValue={(distillery: Distillery | null) =>
                          distillery?.name || ""
                        }
                        onChange={event => setQuery(event.target.value)}
                      />
                      <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                        <ChevronUpDownIcon
                          className='h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                      </Combobox.Button>
                      <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                        afterLeave={() => setQuery("")}
                      >
                        <Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                          {Array.isArray(filteredDistilleries) &&
                            filteredDistilleries.map(
                              (distillery: Distillery) => (
                                <Combobox.Option
                                  key={distillery.id}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? "bg-black/10 text-black"
                                        : "text-gray-900"
                                    }`
                                  }
                                  value={distillery}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? "font-medium"
                                            : "font-normal"
                                        }`}
                                      >
                                        {distillery.name}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                            active ? "text-white" : "text-black"
                                          }`}
                                        >
                                          <CheckIcon
                                            className='h-5 w-5'
                                            aria-hidden='true'
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              )
                            )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
                <div className='sm:col-span-2 border-t border-gray-200 pt-5'>
                  <h2 className='text-sm text-fog'>
                    Other information
                  </h2>
                  <div className='sm:col-span-6 mt-2'>
                    <label
                      htmlFor='rarity'
                      className='block text-sm font-medium text-black/80 mb-2'
                    >
                      Rarity
                    </label>
                    <select
                      id='rarity'
                      name='rarity'
                      value={data.rarity}
                      onChange={e =>
                        setData({
                          ...data,
                          rarity: e.target.value,
                        })
                      }
                      className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                    >
                      <option value='' disabled>
                        Select a rarity
                      </option>
                      {rarityOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4'>
                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='variety'
                        className='block text-sm font-medium text-black/80 mb-1'
                      >
                        Variety
                      </label>
                      <select
                        id='variety'
                        name='variety'
                        value={data.variety}
                        onChange={e =>
                          setData({
                            ...data,
                            variety: e.target.value,
                          })
                        }
                        className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                      >
                        {varietyOptions.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='release'
                        className='block text-sm font-medium text-black/80 mb-1'
                      >
                        Release
                      </label>
                      <select
                        id='release'
                        name='release'
                        value={data.release}
                        onChange={e =>
                          setData({
                            ...data,
                            release: e.target.value,
                          })
                        }
                        className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
                      >
                        {releaseOptions.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pt-5'>
                <button
                  type='submit'
                  className='inline-flex w-full justify-center rounded-md bg-brand mb-3 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black sm:col-start-2'
                >
                  {createJuiceMutation.isLoading ||
                  updateJuiceMutation.isLoading ? (
                    <ClipLoader color='#ffffff' size={20} />
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  type='button'
                  onClick={onClose}
                  className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                >
                  Cancel
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

const BottleSizesView = ({ data }: { data: Bottle[] }) => {
  return (
    <div className="flex gap-2 mt-3">
      {data.map(itm => (
        <div
          key={itm.id}
          className='inline-flex items-center rounded-md  px-2 py-1 text-xs  text-cloud-900 border border-cloud-100 bg-cloud font-medium text-nowrap'
        >
          {itm.size}mL
        </div>
      ))}
    </div>
  );
};
