import React from "react";
import { Submission } from "src/api/server/types";
import {
  useCompleteSubmissionMutation,
  useAssignToSelfMutation,
} from "../../api/server/mutations";
import SearchInput from "../common/SearchInput";
import { SubmissionsTab } from "./SubmissionsNavTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const SkeletonLoader = () => (
  <div className="animate-pulse">
    <div className="p-3">
      <div className="h-10 bg-gray-200 rounded w-full"></div>
    </div>
    {[...Array(3)].map((_, index) => (
      <div
        key={index}
        className="col-span-1 divide-y m-3 rounded-xl border bg-white shadow-sm"
      >
        <div className="flex-auto py-3">
          <div className="flex justify-between gap-x-4 pl-5">
            <div className="h-6 bg-gray-200 rounded w-1/2"></div>
            <div className="h-6 w-6 bg-gray-200 rounded-full mr-5"></div>
          </div>
          <div className="mt-2 pl-5">
            <div className="h-4 bg-gray-200 rounded w-1/3"></div>
          </div>
        </div>
        <div className="flex space-x-5 py-5 px-5">
          <div className="flex-1">
            <div className="h-40 bg-gray-200 rounded"></div>
          </div>
          <div className="flex-1">
            <div className="h-40 bg-gray-200 rounded"></div>
          </div>
        </div>
        <div className="py-4">
          <div className="h-10 bg-gray-200 rounded-full mx-5"></div>
        </div>
      </div>
    ))}
  </div>
);

const SubmissionsList = ({
  isLoading,
  unassignedSubmissions,
  mySubmissions,
  activeTab,
  onSelectSubmission,
  onToggleDeleteModalIsOpen,
  onOpenImage,
  refetchSubmissions,
  searchTerm,
  onSearchChange,
}: {
  isLoading: boolean;
  unassignedSubmissions?: Submission[];
  mySubmissions?: Submission[];
  activeTab: SubmissionsTab;
  onSelectSubmission: (submission?: Submission) => void;
  onToggleDeleteModalIsOpen: (isOpen: boolean, submission?: Submission) => void;
  onOpenImage: (url: string) => void;
  refetchSubmissions: () => void;
  searchTerm: string;
  onSearchChange: (value: string) => void;
}) => {
  const completeSubmissionMutation = useCompleteSubmissionMutation();
  const assignToSelfMutation = useAssignToSelfMutation();
  const showLoadingSkeleton =
    isLoading || !unassignedSubmissions || !mySubmissions;
  const activeSubmissions =
    activeTab === SubmissionsTab.Submissions
      ? unassignedSubmissions || []
      : mySubmissions || [];

  const onAssignToSelf = (code: string) => {
    assignToSelfMutation.mutate(
      { code: code },
      {
        onSuccess: () => {
          refetchSubmissions();
          toast.success(`Submission ${code} has been assigned to you.`);
        },
        onError: () => {
          toast.error("Error assigning submission");
        },
      }
    );
  };

  const onCompleteSubmission = (code: string) => {
    completeSubmissionMutation.mutate(
      { code: code },
      {
        onSuccess: () => {
          refetchSubmissions();
          toast.success(`Submission ${code} completed!`);
        },
        onError: () => {
          toast.error("Error completing Submission");
        },
      }
    );
  };

  return (
    <div className="overflow-y-auto">
      {showLoadingSkeleton ? (
        <SkeletonLoader />
      ) : (
        <ul className="">
          {activeTab === SubmissionsTab.Submissions && (
            <div className="p-3">
              <SearchInput
                value={searchTerm}
                onChange={onSearchChange}
                placeholder="Search submissions..."
              />
            </div>
          )}
          {activeSubmissions.map((submission) => (
            <li
              key={submission.id}
              className="col-span-1 divide-y m-3 rounded-xl border bg-card text-card-foreground shadow-sm cursor-pointer"
              onClick={() => onSelectSubmission(submission)}
            >
              <div className="flex-auto py-3">
                <div className="flex justify-between gap-x-4 pl-5">
                  <h2 className="text-medium font-bold text-gray-900 truncate">
                    {submission.name}
                  </h2>
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="h-5 w-5 text-red-500 cursor-pointer pr-5 pt-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleDeleteModalIsOpen(true, submission);
                    }}
                  />
                </div>
                <p className="flex text-sm justify-between gap-x-4 pl-5 text-black/50">
                  Submitted {submission.created}
                </p>
              </div>
              <div className="flex space-x-5 py-5 px-5">
                <div className="flex-1">
                  <img
                    src={submission.frontLabel}
                    alt={submission.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpenImage(submission.frontLabel);
                    }}
                    className="w-full h-auto rounded-md bg-gray-100 object-cover object-center cursor-zoom-in"
                  />
                </div>
                <div className="flex-1">
                  <img
                    src={submission.backLabel}
                    alt={submission.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpenImage(submission.backLabel);
                    }}
                    className="w-full h-auto rounded-md bg-gray-100 object-cover object-center cursor-zoom-in"
                  />
                </div>
              </div>
              <div className="-mt-px flex">
                <div className="flex w-0 flex-1">
                  {!!submission.assignee && (
                    <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-between gap-x-3 rounded-bl-lg border border-transparent py-3 text-sm font-medium text-gray-900 px-3">
                      <div className="flex items-center">
                        <img
                          className="h-8 w-8 flex-shrink-0 mr-2 rounded-full bg-gray-300"
                          src={submission.assignee.avatar}
                          alt=""
                        />
                        Assigned to you
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onCompleteSubmission(submission.upc);
                        }}
                        className="rounded bg-green-50 px-2 py-1 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-100"
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-1.5 h-4 w-4"
                        />
                        Mark as done
                      </button>
                    </div>
                  )}
                  {!submission.assignee && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onAssignToSelf(submission.upc);
                      }}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-900 hover:bg-black/5"
                    >
                      🙋‍♂️ Assign to me
                    </button>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SubmissionsList;
