export const varietyOptions = [
  "blended grain",
  "premixed cocktail",
  "flavored whiskey",
  "american single malt",
  "flavored",
  "liqueur",
  "peated blended malt",
  "bourbon",
  "white",
  "blended malt scotch",
  "white whiskey",
  "blended whiskey",
  "moonshine",
  "rye",
  "wheat whiskey",
  "blended scotch",
  "blended malt",
  "blended american whiskey",
  "tennessee whiskey",
  "irish whiskey",
  "whiskey",
  "corn",
  "single malt",
  "american whiskey",
  "other",
  "single malt scotch",
  "blended",
  "peated single malt",
  "canadian",
  "japanese whisky",
];

export const releaseOptions = [
  "ongoing",
  "annual",
  "biannual",
  "triannual",
  "quarterly",
  "discontinued",
  "random",
  "distillery only",
  "unknown",
];

export const rarityOptions = [
  "common",
  "uncommon",
  "rare",
  "unicorn",
  "impossible",
];

export const sizeOptions = [
  "50",
  "100",
  "200",
  "280",
  "350",
  "375",
  "500",
  "700",
  "750",
  "900",
  "1000",
  "1500",
  "1750",
  "3000",
];
