import { useState } from "react";
import { Distillery } from "../api/server/types";
import {
  useCreateDistilleryMutation,
  useUpdateDistilleryMutation,
} from "../api/server/mutations";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { toBase64 } from "../utils/image";
import { Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

export const AddEditDistillery = ({
  distillery,
  isOpen,
  onCancel,
  onCreateSuccess,
  onEditSuccess,
}: {
  distillery?: Distillery;
  isOpen: boolean;

  onCancel: VoidFunction;
  onCreateSuccess?: VoidFunction;
  onEditSuccess?: VoidFunction;
}) => {
  const [updatedDistillery, setUpdatedDistillery] = useState<any>(
    distillery || {}
  );
  const [imageUrl, setImageUrl] = useState<string>(distillery?.logoUrl || "");

  const createDistilleryMutation = useCreateDistilleryMutation();
  const updateDistilleryMutation = useUpdateDistilleryMutation();

  const handleSave = () => {
    if (!!distillery) {
      updateDistilleryMutation.mutate(
        { id: distillery.id, distillery: updatedDistillery },
        {
          onSuccess: () => {
            onCancel();
            onEditSuccess && onEditSuccess();
          },
          onError: () => {
            toast.error("Error updating distillery.");
          },
        }
      );
    } else {
      createDistilleryMutation.mutate(updatedDistillery, {
        onSuccess: () => {
          toast.success(`${updatedDistillery?.name} created successfully!`);
          onCancel();
          onCreateSuccess && onCreateSuccess();
        },
        onError: () => {
          toast.error("Error creating distillery");
        },
      });
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const { base64 } = await toBase64(file);
      setUpdatedDistillery({ ...distillery, image: base64 });
      setImageUrl(base64);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      className='fixed z-100 inset-0 overflow-y-auto flex'
    >
      <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />

      <div className='bg-white rounded-lg p-6 relative z-10 max-w-2xl m-auto w-full'>
        <div className='flex '>
          <div className='w-12 h-12 border shadow-sm rounded-xl inline-flex items-center justify-center text-fog-foreground text-xl mr-4'>
            <FontAwesomeIcon icon={faBuilding} />
          </div>
          <div className='flex flex-col'>
            <h1 className='text-lg font-bold text-black/80'>
              Edit {distillery?.name}
            </h1>
            <p className='text-fog text-sm'>
              All edits will be seen by other users.
            </p>
          </div>
        </div>

        <hr className='border-t -mx-6 m-6' />

        <div className='flex flex-col gap-4'>
          <div className='grid grid-cols-6'>
            <label
              htmlFor='distileryLogo'
              className='text-fog-foreground col-span-2'
            >
              Company logo
            </label>
            <div className='flex col-span-4 gap-5 ite'>
              {imageUrl ? (
                <img
                  key={distillery?.logoUrl}
                  src={imageUrl}
                  alt={distillery?.name}
                  className='mx-auto h-16 w-16 object-contain rounded-lg'
                />
              ) : (
                <div className='mx-auto h-16 w-16 object-cover rounded-lg bg-gray-300' />
              )}
              <div className='flex justify-center items-center border rounded-xl relative flex-1 hover:bg-border'>
                <div className='text-fog-foreground text-sm text-center'>
                  <strong className='text-brand'>Click to upload</strong> or
                  drag and drop
                  <br />
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </div>
                <input
                  id='distileryLogo'
                  type='file'
                  accept='.png, .jpeg, .gif'
                  className='size-full absolute left-0 top-0 opacity-0 z-10'
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>

          <div className='grid grid-cols-6 items-center'>
            <label
              htmlFor='name'
              className='block text-sm font-medium text-black/80 mb-2 col-span-2'
            >
              Name
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={updatedDistillery?.name}
              onChange={e =>
                setUpdatedDistillery({
                  ...updatedDistillery,
                  name: e.target.value,
                })
              }
              className='col-span-4 block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6'
            />
          </div>

          <div className='grid grid-cols-6 items-center'>
            <label
              htmlFor='location'
              className='block text-sm font-medium text-black/80 mb-2 col-span-2'
            >
              Location
            </label>
            <input
              type='text'
              id='location'
              name='location'
              value={updatedDistillery?.location}
              onChange={e =>
                setUpdatedDistillery({
                  ...updatedDistillery,
                  location: e.target.value,
                })
              }
              className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 col-span-4'
            />
          </div>
          <div className='grid grid-cols-6 items-center'>
            <label
              htmlFor='founded'
              className='block text-sm font-medium text-black/80 mb-2 col-span-2'
            >
              Founded
            </label>
            <input
              type='text'
              id='founded'
              name='founded'
              value={updatedDistillery?.founded}
              onChange={e =>
                setUpdatedDistillery({
                  ...updatedDistillery,
                  founded: e.target.value,
                })
              }
              className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 col-span-4'
            />
          </div>

          <div className='grid grid-cols-6 items-center'>
            <label
              htmlFor='website'
              className='block text-sm font-medium text-black/80 mb-2 col-span-2'
            >
              Website
            </label>
            <input
              type='text'
              id='website'
              name='website'
              value={updatedDistillery?.website}
              onChange={e =>
                setUpdatedDistillery({
                  ...updatedDistillery,
                  website: e.target.value,
                })
              }
              className='block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 col-span-4'
            />
          </div>
        </div>

        <hr className='border-t -mx-6 m-6' />

        <div className='mt-6 flex justify-end space-x-4'>
          <button
            onClick={onCancel}
            className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
          >
            Cancel
          </button>

          <button
            onClick={handleSave}
            className='mt-3 inline-flex w-full justify-center rounded-md bg-brand px-3 py-3 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-black hover:bg-brand/80 sm:col-start-1 sm:mt-0'
          >
            {updateDistilleryMutation.isLoading ||
            createDistilleryMutation.isLoading ? (
              <ClipLoader color='#ffffff' size={20} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
