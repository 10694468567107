import { useNotification } from "src/context/NotificationContext";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const ErrorModal = () => {
  const {
    notification: { show, type, retryFunction, cancelFunction, message },
    hideNotification,
  } = useNotification();

  return (
    <Transition.Root
      show={show && type === "error"}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-100"
        open={show}
        onClose={() => hideNotification()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative p-5 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <div className="">
                  <h2 className="text-xl mb-5 ">Error:</h2>
                  <p className="mb-7">{message}</p>
                  <button
                    onClick={() => {
                      retryFunction?.();
                      hideNotification();
                    }}
                    className="inline-flex w-full justify-center rounded-md bg-orange-500 mb-3 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black sm:col-start-2"
                  >
                    Retry
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      cancelFunction?.();
                      hideNotification();
                    }}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ErrorModal;
