import React, { createContext, useState, useContext, ReactNode } from "react";

type NotificationType = "success" | "error";

interface Notification {
  show: boolean;
  message: string;
  type: NotificationType;
  retryFunction?: () => void;
  cancelFunction?: () => void;
}

interface ShowNotificationProps {
  message: string;
  type: NotificationType;
  retryFunction?: () => void;
  cancelFunction?: () => void;
}

interface NotificationContextType {
  notification: Notification;
  showNotification: (props: ShowNotificationProps) => void;
  hideNotification: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "success",
  });

  const showNotification = ({
    message = "An error occurred",
    type = "error",
    retryFunction,
    cancelFunction,
  }: ShowNotificationProps) => {
    setNotification({
      show: true,
      message,
      type,
      retryFunction,
      cancelFunction,
    });
  };

  const hideNotification = () => {
    setNotification({ ...notification, show: false, retryFunction: undefined });
  };

  return (
    <NotificationContext.Provider
      value={{ notification, showNotification, hideNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
