import React, { useState, useEffect } from "react";
import { User } from "../api/server/types";
import UserList from "src/components/Users/UserList";
import UserDetails from "src/components/Users/UserDetails";
import { useGetUsers } from "src/api/server/queries";
import { useDebounce } from "use-debounce";

export default function Users() {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  const { data, isLoading, error, refetch } = useGetUsers(
    debouncedSearchTerm,
    page
  );

  useEffect(() => {
    refetch();
  }, [debouncedSearchTerm, page, refetch]);

  const handleUserClick = (userId: string) => {
    setSelectedUserId(userId);
  };

  const handleSearchChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className="flex h-full">
      <UserList
        users={data?.data || []}
        onUserClick={handleUserClick}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        totalUsers={data?.total || 0}
        currentPage={page}
        onPageChange={handlePageChange}
        isLoading={isLoading}
      />
      <main className="flex-1 pb-8">
        {selectedUserId ? (
          <UserDetails userId={selectedUserId} />
        ) : (
          <div className="flex h-screen">
            <div className="m-auto text-center">
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                No user selected
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Select a user to get started
              </p>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
