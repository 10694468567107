import React from "react";

interface DistilleryCardProps {
  distilleryName: string;
  owner: string;
  imageUrl: string;
}

const DistilleryCard: React.FC<DistilleryCardProps> = ({
  distilleryName,
  imageUrl,
}) => {
  return (
    <li className="col-span-1 divide-y divide-black/10 bg-white shadow-sm rounded-lg border hover:bg-black/5 hover:shadow-none">
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-black">
              {distilleryName}
            </h3>
          </div>
        </div>
        <img
          className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
          src={imageUrl}
          alt=""
        />
      </div>
    </li>
  );
};

export default DistilleryCard;
