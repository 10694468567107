import { useGetJuices } from "src/api/server/queries";
import SearchBar from "../SearchBar";
import { useEffect, useState } from "react";
import { Juice } from "src/api/server/types";
import JuiceCard from "../JuiceCard";
import useError from "src/hooks/useError";
import { useDebounce } from "use-debounce";
import { ClipLoader } from "react-spinners";

const JuiceSearch = ({
  show,
  onSelectJuice,
}: {
  show: boolean;
  onSelectJuice: (juice: Juice) => void;
}) => {
  const [juicePage, setJuicePage] = useState(1);
  const [, setIsRefetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const {
    data: juicesData,
    refetch,
    error,
    remove,
    isRefetching,
    isLoading,
  } = useGetJuices(searchTerm, juicePage);

  const showLoadingSpinner = isLoading || isRefetching || !juicesData;

  const juices = juicesData?.data || [];

  useError(error, remove);

  useEffect(() => {
    setIsRefetching(true);
    refetch().finally(() => setIsRefetching(false));
  }, [debouncedSearchTerm, refetch]);

  if (!show) return null;
  return (
    <>
      <div className='m-6'>
        <SearchBar
          setSearchTerm={setSearchTerm}
          setIsRefetching={setIsRefetching}
        />
      </div>
      {showLoadingSpinner &&
        Array.from({ length: 10 }, (_, i) => i + 1).map(index => (
          <div className='flex min-w-0 gap-x-4 h-24 border-b items-center pl-7'>
            <div className='h-12 w-12 flex-none rounded-full bg-gray-200 animate-pulse' />
            <div className='min-w-0 flex-auto space-y-2'>
              <div className='h-4 w-9/12 bg-gray-200 animate-pulse rounded'></div>
              <div className='h-4 w-2/12 bg-gray-200 animate-pulse rounded'></div>
            </div>
          </div>
        ))}
      {!showLoadingSpinner && (
        <ul className=''>
          {juices.map((juice: Juice) => (
            <JuiceCard
              key={juice.id}
              juice={juice}
              linkToJuicePage={false}
              showActions={false}
              onClick={() => {
                onSelectJuice(juice);
              }}
            />
          ))}
        </ul>
      )}
    </>
  );
};

export default JuiceSearch;
