import { useEffect, useState } from "react";
import { useGetDistilleries } from "../api/server/queries";
import { Link } from "react-router-dom";
import { Distillery } from "../api/server/types";
import SearchBar from "../components/SearchBar";
import { useDebounce } from "use-debounce";
import { CircularProgress, Pagination } from "@mui/material";
import DistilleryCard from "../components/DistilleryCard";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AddEditDistillery } from "src/components/AddEditDistillery";

const Distilleries = () => {
  const [distilleries, setDistilleries] = useState([] as Distillery[]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [isRefetching, setIsRefetching] = useState(false);
  const [page, setPage] = useState(1);

  const { data, isLoading, refetch } = useGetDistilleries(searchTerm, page);

  const onCreateSuccessHandle = () => {
    setIsModalOpen(false);
    refetch();
  };

  const handleCancelEditing = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (data) {
      setDistilleries(data.data);
    }
  }, [data]);

  useEffect(() => {
    setIsRefetching(true);
    refetch().finally(() => setIsRefetching(false));
  }, [debouncedSearchTerm, page, refetch]);

  return (
    <>
      <div>
        <div className='m-6'>
          <SearchBar
            setSearchTerm={setSearchTerm}
            setIsRefetching={setIsRefetching}
          />
        </div>
        <div className='border-b border-t pb-5 sm:flex sm:items-center sm:justify-between p-5'>
          <h2 className='text-3xl font-bold tracking-tight'>Distilleries</h2>
          <div className='mt-3 sm:ml-4 sm:mt-0'>
            <button
              onClick={() => setIsModalOpen(true)}
              className='inline-flex items-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white hover:bg-black/90 hover:shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black'
            >
              <FontAwesomeIcon icon={faPlus} className='mr-3' />
              New Distillery
            </button>
          </div>
        </div>
        {isLoading || isRefetching ? (
          <div className='flex justify-center items-start pt-32 min-h-screen'>
            <CircularProgress />
          </div>
        ) : (
          <ul className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 p-5'>
            {distilleries.map(distillery => (
              <Link to={`/distilleries/${distillery.id}`} key={distillery.id}>
                <DistilleryCard
                  key={distillery.id}
                  distilleryName={distillery.name}
                  imageUrl={distillery.logoUrl}
                  owner={""}
                />
              </Link>
            ))}
          </ul>
        )}

        {data && (
          <Pagination
            count={Math.ceil(data.total / 20)}
            page={page}
            onChange={(event, value) => setPage(value)}
            className='flex justify-center items-center pb-10'
          />
        )}
      </div>

      {isModalOpen && (
        <AddEditDistillery
          onCreateSuccess={onCreateSuccessHandle}
          onCancel={handleCancelEditing}
          isOpen={isModalOpen}
        />
      )}
    </>
  );
};

export default Distilleries;
