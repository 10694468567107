import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useGetUsersCollection } from "../../api/server/queries";
import { Collectable, BottleSearchResult } from "src/api/server/types";
import SearchInput from "../common/SearchInput";
import AddItemModal from "./AddItemModal";
import {
  PlusIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { useAddToCollectionMutation } from "src/api/server/mutations";
import { toast } from "sonner";

interface UserCollectionProps {
  userId: string;
}

export default function UserCollection({ userId }: UserCollectionProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const addToCollection = useAddToCollectionMutation();

  const { data, isLoading, error } = useGetUsersCollection(
    userId,
    searchTerm,
    page
  );

  const handleAddItem = (item: BottleSearchResult) => {
    addToCollection.mutate(
      { userId, bottleId: item.id },
      {
        onSuccess: (newItem) => {
          toast.success(`${item.name} added to collection successfully!`);
          setIsAddModalOpen(false);

          queryClient.setQueryData(
            ["userCollection", userId, searchTerm, page],
            (oldData: any) => {
              if (oldData && oldData.data) {
                const newCollectable: Collectable = {
                  id: newItem.id,
                  name: newItem.name,
                  image_url: newItem.image_url,
                  size: newItem.size,
                  total: 1,
                  is_favorite: newItem.is_favorite,
                  juice_id: newItem.juice_id,
                  proof: newItem.proof,
                  status: newItem.status,
                  variety: newItem.variety,
                };

                const updatedData = [newCollectable, ...oldData.data];

                if (page !== 1 && updatedData.length > 10) {
                  updatedData.pop();
                }

                return {
                  ...oldData,
                  data: updatedData,
                  total: oldData.total + 1,
                };
              }
              return oldData;
            }
          );

          if (page !== 1) {
            queryClient.invalidateQueries([
              "userCollection",
              userId,
              searchTerm,
              1,
            ]);
          }
        },
        onError: (error) => {
          toast.error(
            `Failed to add ${item.name} to collection. Please try again.`
          );
        },
      }
    );
  };

  const SkeletonItem = () => (
    <li className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6 animate-pulse">
      <div className="flex min-w-0 gap-x-4">
        <div className="h-12 w-12 flex-none rounded-full bg-gray-200" />
        <div className="min-w-0 flex-auto">
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
          <div className="h-3 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <div className="h-4 bg-gray-200 rounded w-24 mb-2"></div>
          <div className="h-3 bg-gray-200 rounded w-16"></div>
        </div>
        <div className="h-5 w-5 bg-gray-200 rounded"></div>
      </div>
    </li>
  );

  const totalPages = Math.ceil((data?.total || 0) / 10);

  return (
    <div className="p-8">
      <div className="mx-auto max-w-7xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            User's Collection
          </h2>
          <button
            type="button"
            onClick={() => setIsAddModalOpen(true)}
            className="inline-flex items-center rounded-3xl bg-black px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Add
          </button>
        </div>
        <div className="mb-4">
          <SearchInput
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search Collection"
          />
        </div>
        <ul
          role="list"
          className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
        >
          {isLoading ? (
            <>
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
            </>
          ) : error ? (
            <li className="px-4 py-5 text-center text-red-500 sm:px-6">
              Error loading collection
            </li>
          ) : data?.data.length === 0 ? (
            <li className="px-4 py-5 text-center sm:px-6">
              No items in collection
            </li>
          ) : (
            data?.data.map((collectable: Collectable) => (
              <li
                key={collectable.id}
                className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
              >
                <div className="flex min-w-0 gap-x-4">
                  <img
                    className="h-12 w-12 flex-none rounded-full bg-gray-50"
                    src={collectable.image_url}
                    alt=""
                  />
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      <a href={`#${collectable.id}`}>
                        <span className="absolute inset-x-0 -top-px bottom-0" />
                        {collectable.name}
                      </a>
                    </p>
                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                      <a
                        href={`#${collectable.id}`}
                        className="relative truncate hover:underline"
                      >
                        {collectable.size}mL
                      </a>
                    </p>
                  </div>
                </div>
                <div className="flex shrink-0 items-center gap-x-4">
                  <div className="hidden sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">
                      {collectable.total}
                    </p>
                  </div>
                </div>
              </li>
            ))
          )}
        </ul>
        <div className="mt-4 flex items-center justify-between py-2">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => setPage(page > 1 ? page - 1 : 1)}
              disabled={page === 1}
              className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => setPage(page < totalPages ? page + 1 : totalPages)}
              disabled={page === totalPages}
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">{(page - 1) * 10 + 1}</span> to{" "}
                <span className="font-medium">
                  {Math.min(page * 10, data?.total || 0)}
                </span>{" "}
                of <span className="font-medium">{data?.total || 0}</span>{" "}
                results
              </p>
            </div>
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md"
                aria-label="Pagination"
              >
                <button
                  onClick={() => setPage(page > 1 ? page - 1 : 1)}
                  disabled={page === 1}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  {page}
                </button>
                <button
                  onClick={() =>
                    setPage(page < totalPages ? page + 1 : totalPages)
                  }
                  disabled={page === totalPages}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <AddItemModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        userId={userId}
        onAddItem={handleAddItem}
      />
    </div>
  );
}
