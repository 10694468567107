import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserButton } from "./UserButton";
import odLogo from "../assets/odLogo.png";
import { useAuth } from "src/context/AuthContext";
import { cn } from "src/utils/utils";
import { Menu, Transition } from "@headlessui/react";
import {
  Factory,
  Gauge,
  ScanBarcode,
  Users,
  Droplet,
  Key,
  Store,
  Shapes,
  Package,
  ChevronDown,
  Star,
  Cog,
} from "lucide-react";

const StaticSidebar = () => {
  const { accountType } = useAuth();
  const location = useLocation();

  let navigationItems = [];

  if (accountType === "editor" || accountType === "admin") {
    navigationItems.push({
      name: "Submissions",
      href: "/submissions",
      count: null,
      icon: <ScanBarcode />,
      current: false,
    });
    navigationItems.push({
      name: "Juices",
      href: "/juices",
      count: null,
      icon: <Droplet />,
      current: false,
    });
  }

  if (
    accountType === "distillery" ||
    accountType === "admin" ||
    accountType === "editor"
  ) {
    navigationItems.push({
      name: "Distilleries",
      href: "/distilleries",
      count: null,
      icon: <Factory />,
      current: false,
    });
  }

  if (accountType === "store") {
    navigationItems.push({
      name: "Products",
      href: "/products",
      count: null,
      icon: <Shapes />,
      current: false,
    });
    navigationItems.push({
      name: "Inventory",
      href: "/inventory",
      count: null,
      icon: <Package />,
      current: false,
    });
    navigationItems.push({
      name: "Reviews",
      href: "/reviews",
      count: null,
      icon: <Star />,
      current: false,
    });
    navigationItems.push({
      name: "Settings",
      href: "/settings",
      count: null,
      icon: <Cog />,
      current: false,
    });
  }

  if (accountType === "admin") {
    navigationItems.unshift({
      name: "Dashboard",
      href: "/dashboard",
      count: null,
      icon: <Gauge />,
      current: false,
    });
    navigationItems.push({
      name: "Users",
      href: "/users",
      count: null,
      icon: <Users />,
      current: false,
    });
    navigationItems.push({
      name: "Stores",
      href: "/stores",
      count: null,
      icon: <Store />,
      current: false,
    });
    navigationItems.push({
      name: "Admin",
      href: "/admin",
      count: null,
      icon: <Key />,
      current: false,
    });
  }

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col border-r bg-white">
      <div className="flex grow flex-col gap-y-2 overflow-y-auto px-3 bg-taupe ring-1 ring-white/5">
        <div className="flex h-16 shrink-0 items-center">
          <img className="h-8 w-auto" src={odLogo} alt="OnlyDrams" />
        </div>
        {/* {accountType === "admin" && (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="group w-full rounded-md bg-gray-100 px-2 py-2 text-left text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-green-900 focus:ring-offset-2 focus:ring-offset-gray-100">
                <span className="flex w-full items-center justify-between">
                  <span className="flex min-w-0 items-center justify-between space-x-3">
                    <img
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                      src="https://scontent-ord5-1.xx.fbcdn.net/v/t39.30808-1/305629959_404903691775605_1204309262075488975_n.jpg?stp=c80.0.480.480a_dst-jpg_p480x480&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=z5w_TNUEDSUQ7kNvgF-l0Og&_nc_ht=scontent-ord5-1.xx&oh=00_AfDrxNf6WXfrIwwlHwVRVaZ5TJPND8Gjc9wqAbq_ho3cRA&oe=663D821B"
                      alt=""
                    />
                    <span className="flex min-w-0 flex-1 flex-col">
                      <span className="truncate text-sm font-medium text-gray-900">
                        Bee Liquors
                      </span>
                      <span className="truncate text-sm text-gray-500">
                        710 N Hobart Rd Suite B, Hobart, IN 46342
                      </span>
                    </span>
                    <ChevronDown
                      className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </span>
                </span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 right-0 z-10 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <span className="flex min-w-0 items-center p-3 justify-between space-x-3 rounded-md hover:bg-gray-200 cursor-pointer">
                        <img
                          className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                          src="https://scontent-ord5-1.xx.fbcdn.net/v/t39.30808-1/305629959_404903691775605_1204309262075488975_n.jpg?stp=c80.0.480.480a_dst-jpg_p480x480&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=z5w_TNUEDSUQ7kNvgF-l0Og&_nc_ht=scontent-ord5-1.xx&oh=00_AfDrxNf6WXfrIwwlHwVRVaZ5TJPND8Gjc9wqAbq_ho3cRA&oe=663D821B"
                          alt=""
                        />
                        <span className="flex min-w-0 flex-1 flex-col">
                          <span className="truncate text-sm font-medium text-gray-900">
                            Bee Liquors
                          </span>
                          <span className="truncate text-sm text-gray-500">
                            710 N Hobart Rd Suite B, Hobart, IN 46342
                          </span>
                        </span>
                      </span>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )} */}
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-1">
            {navigationItems.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={cn(
                    item.current || location.pathname.startsWith(item.href)
                      ? "bg-black text-white group-hover:bg-black group-hover:text-white"
                      : "text-black hover:text-black hover:bg-black/5",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  )}
                >
                  {item.icon}
                  {item.name}
                  {item.count !== null && item.count > 0 && (
                    <span
                      className={cn(
                        item.current || location.pathname.startsWith(item.href)
                          ? "text-white"
                          : "text-background text-black",
                        "ml-auto inline-flex items-center rounded-full bg-red-500 px-2 py-1 text-xs font-medium text-white"
                      )}
                    >
                      {item.count}
                    </span>
                  )}
                </Link>
              </li>
            ))}
            <UserButton />
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default StaticSidebar;
