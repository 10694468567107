import { useEffect, useState } from "react";

const useRefetchOnPageChange = (
  page: number,
  refetch: () => void,
  isRefetching: boolean
) => {
  const [prevPage, setPrevPage] = useState(1);
  const [pageHasChanged, setPageHasChanged] = useState(false);
  const isRefetchingAfterPageChange = isRefetching && pageHasChanged;

  useEffect(() => {
    if (prevPage !== page && !pageHasChanged) {
      setPrevPage(page);
      setPageHasChanged(true);
      refetch();
    }
  }, [page, prevPage, pageHasChanged, setPrevPage, setPageHasChanged, refetch]);

  useEffect(() => {
    if (pageHasChanged && !isRefetching) setPageHasChanged(false);
  }, [pageHasChanged, isRefetching]);

  return isRefetchingAfterPageChange;
};

export default useRefetchOnPageChange;
