import { useState, Fragment } from "react";
import { Bottle, Juice } from "../api/server/types";
import { Dialog, Transition } from "@headlessui/react";
import { useGetUPCs } from "../api/server/queries";
import {
  useAddUPCMutation,
  useDeleteSubmissionMutation,
  useDeleteUpcMutation,
} from "../api/server/mutations";
import { toast } from "react-toastify";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

const BottleCard = ({
  bottle,
  setIsEditingBottle,
  setCurrentBottle,
  juice,
}: {
  bottle: Bottle;
  setIsEditingBottle: any;
  setCurrentBottle: any;
  juice: Juice;
}) => {
  const [isEditingUPCs, setIsEditingUPCs] = useState(false);
  const { data: upcs, refetch, isLoading } = useGetUPCs(bottle.id);
  const addUPCMutation = useAddUPCMutation();
  const deleteUPCMutation = useDeleteUpcMutation();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [bottleId, setBottleId] = useState<string>("");
  const [possiblyDeleteUPC, setPossiblyDeleteUPC] = useState<string>("");

  const handleClick = () => {
    setCurrentBottle(bottle);
    setIsEditingBottle(() => true);
  };

  const handleEditUPCs = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setIsEditingUPCs(true);
  };

  const handleAddUPC = (upc: string) => {
    addUPCMutation.mutate(
      { bottleId: bottle.id, upc: upc },
      {
        onSuccess: () => {
          refetch();
          toast.success("UPC added successfully!");
        },
        onError: () => {
          toast.error("Error adding UPC");
        },
      }
    );
  };

  const handleDeleteUPC = (bottleId: string, code: string) => {
    deleteUPCMutation.mutate(
      { bottleId: bottleId, code: code },
      {
        onSuccess: () => {
          refetch();
          toast.success("UPC was deleted successfully!");
        },
        onError: () => {
          toast.error("Error deleting UPC");
        },
      }
    );
  };

  return (
    <>
      <li
        className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-black/10 sm:px-5 lg:px-5"
        onClick={handleClick}
      >
        <div className="flex min-w-0 gap-x-4">
          <img
            src={bottle.imageUrl}
            alt={juice.name}
            className="h-12 w-12 flex-none rounded-full bg-white object-contain"
          />
          <div className="min-w-0 flex-auto">
            <h2 className="text-sm font-semibold leading-6 text-black">
              {juice.name}
            </h2>
            <span className="inline-flex items-center rounded-md bg-black/10 px-2 py-1 text-xs font-medium text-black">
              {bottle.size}mL
            </span>
          </div>
        </div>
        <button
          onClick={handleEditUPCs}
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium border transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-muted text-secondary-foreground shadow-sm hover:bg-muted/80 h-9 px-4 py-2"
        >
          Edit UPCs
        </button>
      </li>
      <Transition.Root show={isEditingUPCs} as={Fragment}>
        <Dialog
          open={isEditingUPCs}
          onClose={() => setIsEditingUPCs(false)}
          className="fixed inset-0 z-100 overflow-y-auto"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 mx-auto bg-white rounded-lg shadow-lg">
                  {!isLoading && (
                    <>
                      <div className="flex flex-col space-y-1.5 text-center sm:text-left">
                        <Dialog.Title className="text-lg font-semibold leading-none tracking-tight">
                          {upcs ? (
                            upcs.length > 1 ? (
                              `${upcs.length} UPCs`
                            ) : upcs.length === 1 ? (
                              "1 UPC"
                            ) : (
                              <span className="text-lg font-semibold leading-none tracking-tight">
                                No UPCs
                              </span>
                            )
                          ) : (
                            <span className="text-lg font-semibold leading-none tracking-tight">
                              No UPCs
                            </span>
                          )}
                        </Dialog.Title>
                        <p className="text-sm text-black/60">
                          It is not uncommon for a bottle to have several upcs.
                        </p>
                      </div>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                        className="space-y-4"
                      >
                        <div>
                          <input
                            type="text"
                            name="upc"
                            id="upc-input"
                            placeholder="Enter a new UPC and hit enter"
                            className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-red-500 sm:text-sm p-2 mt-5"
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                handleAddUPC(event.currentTarget.value);
                                event.currentTarget.value = "";
                              }
                            }}
                          />
                        </div>
                        <div>
                          <div className="flex flex-wrap gap-2">
                            {upcs?.map((upc) => (
                              <span
                                onClick={() => {
                                  setIsEditingUPCs(false);
                                  setIsConfirmingDelete(true);
                                  setPossiblyDeleteUPC(upc.id);
                                  setBottleId(bottle.id);
                                }}
                                key={upc.id}
                                className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-transparent hover:text-red-500 hover:ring-red-500 cursor-pointer hover:font-bold"
                              >
                                {upc.code} &times;
                              </span>
                            ))}
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ConfirmDeleteDialog
        open={isConfirmingDelete}
        onClose={() => {
          setIsConfirmingDelete(false);
          setIsEditingUPCs(true);
        }}
        onConfirm={() => {
          handleDeleteUPC(bottleId, possiblyDeleteUPC);
          setIsConfirmingDelete(false);
          setIsEditingUPCs(true);
        }}
      />
    </>
  );
};

export default BottleCard;
