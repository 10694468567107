import { useAuth } from "../context/AuthContext";
import { LogOut } from "lucide-react";

const logoutIcon = () => (
  <svg
    width='24px'
    height='24px'
    stroke-width='1.5'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    color='#000000'
  >
    <path
      d='M19 12H12M12 12L15 15M12 12L15 9'
      stroke='#000000'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    ></path>
    <path
      d='M19 6V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V18'
      stroke='#000000'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    ></path>
  </svg>
);

export const UserButton: React.FC = () => {
  const { setAccessToken, setIsLoggedIn } = useAuth();

  const handleSignOut = () => {
    localStorage.removeItem("accessToken");
    setAccessToken("");
    setIsLoggedIn(false);
  };

  return (
    <li className='py-2 mt-auto border-t'>
      <button
        onClick={handleSignOut}
        className='flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-black hover:bg-black/5 w-full text-left'
      >
        <LogOut />
        <span>Sign out</span>
      </button>
    </li>
  );
};
