import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const SearchBar = ({
  setSearchTerm,
  setIsRefetching,
}: {
  setSearchTerm: (searchTerm: string) => void;
  setIsRefetching: (isRefetching: boolean) => void;
}) => {
  return (
    <div className='z-100 top-0 flex shrink-0 items-center'>
      <form className='flex flex-1' onSubmit={e => e.preventDefault()}>
        <label htmlFor='search-field' className='sr-only'>
          Search
        </label>
        <div className='relative w-full'>
          <MagnifyingGlassIcon
            className='pointer-events-none absolute inset-y-0 left-3 h-full w-5 text-gray-500'
            aria-hidden='true'
          />
          <input
            id='search-field'
            className='block w-full border bg-transparent py-0 pl-10 pr-0 text-black focus:ring-0 sm:text-sm rounded-lg border-border h-10'
            placeholder='Search...'
            type='search'
            name='search'
            onChange={e => {
              setIsRefetching(true);
              setSearchTerm(e.target.value);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
