import { AxiosResponse } from "axios";

let isRefreshing = false;
let refreshSubscribers: ((accessToken: string) => void)[] = [];

function subscribeTokenRefresh(cb: (accessToken: string) => void): void {
  refreshSubscribers.push(cb);
}

function onRrefreshed(accessToken: string): void {
  refreshSubscribers.map((cb) => cb(accessToken));
  refreshSubscribers = [];
}

export async function handleAuthError(
  serverInstance: any,
  error: any
): Promise<any> {
  const originalRequest = error.config;
  const baseUrl = originalRequest.baseURL;
  if (
    error.response.status === 401 &&
    originalRequest.url !== `${baseUrl}/refresh`
  ) {
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      if (!isRefreshing) {
        isRefreshing = true;
        serverInstance
          .post(`${baseUrl}/refresh`, { refresh_token: refreshToken })
          .then((res: AxiosResponse) => {
            isRefreshing = false;
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            onRrefreshed(res.data.accessToken);
          })
          .catch((err: unknown) => {
            window.dispatchEvent(new Event("unauthorized"));
            localStorage.clear();
          });
      }
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((accessToken: string) => {
          originalRequest.headers["Authorization"] = "Bearer " + accessToken;
          resolve(serverInstance(originalRequest));
        });
      });
    } else {
      window.dispatchEvent(new Event("unauthorized"));
      localStorage.clear();
    }
  }
  return Promise.reject(error);
}
