import { Bottle, Juice } from "../api/server/types";
import BottleCard from "./BottleCard";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const JuicesBottles = ({
  bottles,
  setIsAddingBottle,
  juice,
  setIsEditBottle,
  setCurrentBottle,
}: {
  bottles: Bottle[];
  setIsAddingBottle: any;
  juice: Juice;
  setIsEditBottle: any;
  setCurrentBottle: any;
}) => {
  const handleAddBottle = () => {
    setIsAddingBottle(true);
    setIsEditBottle(false);
  };

  return (
    <>
      <div className="border-b  pb-5 sm:flex sm:items-center sm:justify-between p-5">
        <Link
          to={`/distilleries/${juice.distillery.id}`}
          className="flex items-center text-sm italic hover:underline"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-1" />
        </Link>
        <h3 className="text-2xl font-bold">Bottles</h3>
        <div className="mt-3 sm:ml-4 sm:mt-0">
          <button
            onClick={handleAddBottle}
            className="inline-flex items-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/90 hover:shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-3" />
            New Bottle
          </button>
        </div>
      </div>
      <ul>
        {bottles.map((bottle: Bottle) => (
          <BottleCard
            key={bottle.id}
            bottle={bottle}
            setIsEditingBottle={setIsEditBottle}
            setCurrentBottle={setCurrentBottle}
            juice={juice}
          />
        ))}
      </ul>
    </>
  );
};

export default JuicesBottles;
