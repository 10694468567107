import { useCallback, useEffect, useState } from "react";
import { useNotification } from "src/context/NotificationContext";

const useError = (error: unknown, retryFunction: () => void) => {
  const [blockNotification, setBlockNotification] = useState(false);
  const {
    showNotification,
    hideNotification,
    notification: { show },
  } = useNotification();

  const cancelFunction = useCallback(() => {
    hideNotification();
    setBlockNotification(true);
  }, [hideNotification]);

  useEffect(() => {
    if (error && !show && !blockNotification)
      showNotification({
        message:
          error instanceof Error
            ? error.message
            : typeof error === "string"
            ? error
            : "Something went wrong, please try again.",
        type: "error",
        retryFunction,
        cancelFunction,
      });
  }, [
    error,
    hideNotification,
    showNotification,
    show,
    retryFunction,
    cancelFunction,
    blockNotification,
  ]);
};

export default useError;
