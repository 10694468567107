import {
  getUPCs,
  getDistilleries,
  getDistillery,
  getDistilleryJuices,
  getJuice,
  getJuices,
  getProducersList,
  getUnassignedSubmissions,
  getMyAssignedSubmissions,
  getUsers,
  getUser,
  getUsersCollection,
  searchBottles,
  getStores,
  getDashboard,
  getProducts,
  getCategories,
} from "./requests";
import { useQuery } from "react-query";

export const useGetDistilleries = (searchTerm: string, page: number) => {
  return useQuery("distilleries", () => getDistilleries(searchTerm, page));
};

export const useGetDistillery = (id: string) => {
  return useQuery(["distillery", id], () => getDistillery(id));
};

export const useGetUser = (id: string) => {
  return useQuery(["user", id], () => getUser(id));
};

export const useGetUsersCollection = (
  id: string,
  searchTerm: string,
  page: number
) => {
  return useQuery(["usersCollection", id, page], () =>
    getUsersCollection(id, searchTerm, page)
  );
};

export const useSearchBottles = (searchTerm: string, page: number) => {
  return useQuery("bottles", () => searchBottles(searchTerm, page));
};

export const useGetJuices = (searchTerm: string, page: number) => {
  return useQuery("juices", () => getJuices(searchTerm, page));
};

export const useGetStores = (searchTerm: string, page: number) => {
  return useQuery("stores", () => getStores(searchTerm, page));
};

export const useGetUsers = (searchTerm: string, page: number) => {
  return useQuery("users", () => getUsers(searchTerm, page));
};

export const DISTILLERY_JUICES_QUERY = "distilleryJuices";
export const useGetDistilleryJuices = (
  id: string,
  searchTerm: string,
  page: number
) => {
  const requestedPage = page || 1;

  return useQuery(
    [DISTILLERY_JUICES_QUERY, id, page],
    () => getDistilleryJuices(id, searchTerm, requestedPage),
    { keepPreviousData: true }
  );
};

export const useGetJuice = (juiceId: string) => {
  return useQuery(["juice", juiceId], () => getJuice(juiceId));
};

export const useGetProducersList = () => {
  return useQuery("producersList", () => getProducersList());
};

export const useGetUPCs = (bottleId: string) => {
  return useQuery(`${bottleId}-upcs`, () => getUPCs(bottleId));
};

export const useGetUnassignedSubmissions = (
  searchTerm: string,
  page: number
) => {
  return useQuery("submissions", () =>
    getUnassignedSubmissions(page, searchTerm)
  );
};

export const useGetMyAssignedSubmissions = (page: number) => {
  return useQuery("", () => getMyAssignedSubmissions(page));
};

export const useGetDashboard = () => {
  return useQuery("dashboard", () => getDashboard());
};

export const useGetProducts = () => {
  return useQuery("products", () => getProducts());
};

export const useGetCategories = (page: number) => {
  return useQuery(["categories", page], () => getCategories(page));
};
