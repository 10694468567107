import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const ImageModal = ({
  imageUrl,
  onToggleIsOpen,
  isOpen,
}: {
  imageUrl: string;
  onToggleIsOpen: (isOpen?: boolean) => void;
  isOpen: boolean;
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100"
        open={isOpen}
        onClose={() => onToggleIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative p-5 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <button
                  className="absolute top-0 right-0 mr-1 text-2xl font-bold leading-none text-black hover:text-gray-500"
                  onClick={() => onToggleIsOpen(false)}
                >
                  &times;
                </button>
                {imageUrl && (
                  <div>
                    <img src={imageUrl} alt="Submission" className="w-full" />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ImageModal;
