import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "src/context/AuthContext";
import { Menu, Transition } from "@headlessui/react";
import { useDeleteDistilleryMutation } from "src/api/server/mutations";
import { AddEditDistillery } from "../AddEditDistillery";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import { useGetDistillery } from "src/api/server/queries";
import { CircularProgress } from "@mui/material";

export const DistilleryDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: distillery, isLoading, refetch } = useGetDistillery(id ?? "");

  const { accountType } = useAuth();
  const [isEditingDistillery, setIsEditingDistillery] = useState(false);
  const [isDeletingDistillery, setIsDeletingDistillery] = useState(false);

  const deleteDistilleryMutation = useDeleteDistilleryMutation();

  const navigate = useNavigate();

  const handleDelete = () => {
    if (!distillery) return;
    deleteDistilleryMutation.mutate(distillery?.id, {
      onSuccess: () => {
        toast.success("Distillery deleted successfully!");
        navigate("/distilleries");
      },
      onError: () => {
        toast.error("Error deleting distillery");
      },
    });
  };

  const handleUpdatingComplete = () => {
    toast.success("Distillery updated successfully!");
    setIsEditingDistillery(false);
    refetch();
  };

  const handleCancelEditing = () => {
    setIsEditingDistillery(false);
  };

  if (!distillery || isLoading) {
    return (
      <div className='max-h-full p-4 w-72 rounded-xl border bg-card text-card-foreground shadow-sm min-h-96 flex'>
        <CircularProgress className='m-auto' />
      </div>
    );
  }

  return (
    <>
      <aside className='max-h-full overflow-auto p-4 md:w-72 rounded-xl border bg-card text-card-foreground shadow-sm'>
        <Menu as='div' className='relative flex-shrink-0'>
          <Menu.Button className='absolute right-0 top-0 w-10 h-10 p-1 flex justify-center items-center'>
            <FontAwesomeIcon icon={faEllipsis} className='w-4 h-auto' />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <Menu.Item>
                <button
                  className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left'
                  onClick={() => setIsEditingDistillery(true)}
                >
                  Edit Distillery
                </button>
              </Menu.Item>

              {accountType === "admin" && (
                <Menu.Item>
                  <button
                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-red-500 hover:text-white w-full text-left'
                    onClick={() => setIsDeletingDistillery(true)}
                  >
                    Delete Distillery
                  </button>
                </Menu.Item>
              )}
            </Menu.Items>
          </Transition>
        </Menu>

        <div className='mb-6'>
          {distillery?.logoUrl ? (
            <img
              key={distillery?.logoUrl}
              src={distillery?.logoUrl}
              alt={distillery?.name}
              width={96}
              height={96}
              className='rounded-lg object-contain object-center w-24 h-24 filter drop-shadow-lg'
            />
          ) : (
            <div className='rounded-lg w-24 h-24 bg-gray-300' />
          )}
        </div>

        <h2 className='text-2xl font-semibold mb-6'>{distillery?.name}</h2>

        <div className='mb-6'>
          <p className='text-gray-500 text-sm mb-2'>Location</p>
          <p className='font-medium'> {distillery?.location}</p>
        </div>
        <div className='mb-6'>
          <p className='text-gray-500 text-sm mb-2'>Website</p>
          <a
            href={distillery?.website}
            target='_blank'
            rel='noopener noreferrer'
            className='font-medium text-brand hover:text-brand/80'
          >
            {distillery?.website}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='ml-3' />
          </a>
        </div>
        <div className='mb-0'>
          <p className='text-gray-500 text-sm mb-2'>Founded</p>
          <p className='font-medium text-brand'> {distillery?.founded}</p>
        </div>
      </aside>

      {isEditingDistillery && (
        <AddEditDistillery
          distillery={distillery}
          onEditSuccess={handleUpdatingComplete}
          onCancel={handleCancelEditing}
          isOpen={isEditingDistillery}
        />
      )}

      {isDeletingDistillery && (
        <ConfirmDeleteDialog
          open={isDeletingDistillery}
          onClose={() => setIsDeletingDistillery(false)}
          onConfirm={handleDelete}
          confirmationText={distillery?.name}
          subText='Deleting a distillery will also delete all juices and bottles associated with it. This action can not be undone.'
          isLoading={deleteDistilleryMutation.isLoading}
        />
      )}
    </>
  );
};
