import DistilleryJuices from "../components/Distillery/DistilleryJuices";
import { DistilleryDetails } from "src/components/Distillery/DistilleryDetails";

const Distillery = () => {
  return (
    <div className='p-4 md:p-8 pt-6 md:h-screen grid md:flex gap-8 items-start flex-col md:flex-row'>
      <DistilleryDetails />

      <DistilleryJuices />
    </div>
  );
};

export default Distillery;
