import React, { useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSearchBottles } from "src/api/server/queries";
import { BottleSearchResult } from "src/api/server/types";
import { useDebounce } from "use-debounce";

interface AddItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  onAddItem: (item: BottleSearchResult) => void;
}

export default function AddItemModal({
  isOpen,
  onClose,
  userId,
  onAddItem,
}: AddItemModalProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [selectedItem, setSelectedItem] = useState<BottleSearchResult | null>(
    null
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const {
    data: searchResults,
    isLoading,
    refetch,
  } = useSearchBottles(debouncedSearchTerm, 1);

  useEffect(() => {
    if (debouncedSearchTerm) {
      refetch();
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  }, [debouncedSearchTerm, refetch]);

  useEffect(() => {
    if (!isOpen) {
      clearSearchState();
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchInputRef.current &&
      !searchInputRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  const clearSearchState = () => {
    setSearchTerm("");
    setSelectedItem(null);
    setShowDropdown(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setSelectedItem(null);
    setShowDropdown(true);
  };

  const handleSelectItem = (item: BottleSearchResult) => {
    setSelectedItem(item);
    setSearchTerm(item.name);
    setShowDropdown(false);
    console.log("Selected item ID:", item.id);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedItem) {
      onAddItem(selectedItem);
      onClose();
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <form onSubmit={handleSubmit}>
                  <div className="mt-3">
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Add New Item
                      </Dialog.Title>
                      <div className="mt-2 relative">
                        <div className="relative">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            ref={searchInputRef}
                            autoComplete="off"
                            className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Search for an item"
                            value={searchTerm}
                            onChange={handleSearchChange}
                          />
                          {selectedItem && (
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                              <span className="text-gray-500 sm:text-sm">
                                {selectedItem.size}
                              </span>
                            </div>
                          )}
                        </div>
                        {showDropdown && searchTerm && !selectedItem && (
                          <ul
                            ref={dropdownRef}
                            className="absolute z-[60] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            {isLoading ? (
                              <li className="px-4 py-2 text-gray-700 text-left">
                                Loading...
                              </li>
                            ) : searchResults?.data?.length ? (
                              searchResults.data.map((item) => (
                                <li
                                  key={item.id}
                                  className="cursor-pointer select-none px-4 py-2 hover:bg-indigo-600 hover:text-white text-left"
                                  onClick={() => handleSelectItem(item)}
                                >
                                  <div className="flex justify-between items-center">
                                    <span className="text-left">
                                      {item.name}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                      {item.size}
                                    </span>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li className="px-4 py-2 text-gray-700 text-left">
                                No results found
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      disabled={!selectedItem}
                    >
                      Add Item
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
