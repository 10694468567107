import { Distillery, Entry, Submission } from "src/api/server/types";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSaveEntryMutation } from "../../api/server/mutations";
import {
  varietyOptions,
  releaseOptions,
  rarityOptions,
  sizeOptions,
} from "../../utils/data";
import { Combobox, Transition } from "@headlessui/react";
import { useGetDistilleries } from "../../api/server/queries";
import { cn } from "src/utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import useError from "src/hooks/useError";

const pickables = [
  { key: "age_days", value: "Age (Days)" },
  { key: "age_months", value: "Age (Months)" },
  { key: "age_years", value: "Age (Years)" },
  { key: "batch", value: "Batch" },
  { key: "barrel_number", value: "Barrel Number" },
  { key: "bottled_by", value: "Bottled By" },
  { key: "bottle_date", value: "Bottle Date" },
  { key: "bottle_number", value: "Bottle Number" },
  { key: "distillation_date", value: "Distillation Date" },
  { key: "dumped", value: "Dumped" },
  { key: "finish_age", value: "Finish Age" },
  { key: "finish_type", value: "Finish Type" },
  { key: "floor", value: "Floor" },
  { key: "location", value: "Location" },
  { key: "proof", value: "Proof" },
  { key: "recipe_options", value: "Recipe Options" },
  { key: "recipe", value: "Recipe" },
  { key: "rick", value: "Rick" },
  { key: "rickhouse", value: "Rickhouse" },
  { key: "season", value: "Season" },
  { key: "stave_profile", value: "Stave Profile" },
  { key: "staves", value: "Staves" },
];

const toBase64 = (file: File) =>
  new Promise<{ base64: string }>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result as string;
      resolve({ base64 });
    };
    reader.onerror = (error) => reject(error);
  });

const SubmissionForm = ({
  selectedSubmission,
  onSelectSubmission,
  refetchSubmissions,
  onAddDistilleryToJuice,
}: {
  selectedSubmission: Submission | null;
  onSelectSubmission: (submission?: Submission) => void;
  refetchSubmissions: () => void;
  onAddDistilleryToJuice: (distillery: Distillery | null) => void;
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [distilleryQuery, setDistilleryQuery] = useState("");
  const [selectedDistillery, setSelectedDistillery] =
    useState<Distillery | null>(null);
  const [entry, setEntry] = useState({
    distillery: null as Distillery | null,
  } as Entry);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const saveEntryMutation = useSaveEntryMutation();
  const {
    data: distilleries,
    refetch: refetchDistilleries,
    error,
    remove,
  } = useGetDistilleries(distilleryQuery, 1);
  useError(error, remove);
  const filteredDistilleries = !distilleryQuery
    ? distilleries ?? []
    : distilleries?.data.filter((distillery: Distillery) =>
        distillery.name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(distilleryQuery.toLowerCase().replace(/\s+/g, ""))
      ) ?? [];

  const onAddEntry = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedDistillery)
      saveEntryMutation.mutate(
        { entry: { ...entry, distillery: selectedDistillery } },
        {
          onSuccess: () => {
            refetchSubmissions();
            toast.success("Entry saved!");
            onSelectSubmission();
          },
          onError: () => {
            toast.error("Error saving entry");
          },
        }
      );
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const { base64 } = await toBase64(file);
      setEntry({ ...entry, image: base64 });
      setImageUrl(base64);
    }
  };

  const onChangePickable = (
    e: ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    setSelectedOptions((prevOptions) => {
      if (e.target.checked) return [...prevOptions, option];
      return prevOptions.filter((currentOption) => currentOption !== option);
    });
  };

  useEffect(() => {
    if (selectedSubmission) {
      setEntry((prevEntry) => ({
        ...prevEntry,
        name: selectedSubmission.name || "",
        upc: selectedSubmission.upc || "",
        price: Number(selectedSubmission.price || 0),
        proof: Number(selectedSubmission.proof || 0),
        size: Number(selectedSubmission.size || 0),
      }));
    }
  }, [selectedSubmission]);

  useEffect(() => {
    refetchDistilleries({ queryKey: ["distilleries", distilleryQuery, 1] });
  }, [distilleryQuery, refetchDistilleries]);

  useEffect(() => {
    if (!selectedSubmission) setImageUrl("");
  }, [selectedSubmission]);

  if (!selectedSubmission) return null;

  return (
    <div>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h2 className="text-lg font-semibold leading-6 text-gray-900">
              {selectedSubmission?.name}
            </h2>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <button
              type="button"
              className="rounded-full bg-gray-200 p-2 text-black shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => onSelectSubmission()}
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div className="p-5">
        <form onSubmit={onAddEntry} className="space-y-4">
          <div className="mb-6">
            {!!imageUrl && (
              <img
                src={imageUrl}
                alt="uploaded submission"
                className="mx-auto h-24 w-24 object-cover rounded-full mb-4"
              />
            )}
            {!imageUrl && (
              <div className="mx-auto h-24 w-24 rounded-full mb-4 border flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faImage}
                  className="w-8 h-8 text-gray-500"
                />
              </div>
            )}
            <div className="flex justify-center items-center">
              <input
                type="file"
                accept=".png, .jpeg, .gif"
                className="form-input text-sm text-black/50
              file:mr-4 file:py-2 file:px-4
              file:rounded-md file:border-0
              file:text-sm file:font-semibold
              file:bg-black/10 file:text-black/70
              hover:file:bg-gray-300 cursor-pointer"
                onChange={onFileChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-black/80 mb-1"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={entry.name}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                onChange={(e) =>
                  setEntry({
                    ...entry,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
              <div className="sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-black/80 mb-1"
                >
                  UPC
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={selectedSubmission.upc}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  onChange={(e) =>
                    setEntry({
                      ...entry,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="size"
                  className="block text-sm font-medium text-black/80 mb-2"
                >
                  Size (in mL)
                </label>
                <select
                  id="size"
                  name="size"
                  value={entry.size}
                  onChange={(e) =>
                    setEntry({
                      ...entry,
                      size: Number(e.target.value),
                    })
                  }
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                >
                  <option value="" disabled>
                    Select a size
                  </option>
                  {sizeOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-black/80 mb-1"
              >
                Proof
              </label>
              <input
                type="number"
                name="proof"
                id="proof"
                step="any"
                defaultValue={selectedSubmission.proof}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                onChange={(e) =>
                  setEntry({
                    ...entry,
                    proof: Number(e.target.value),
                  })
                }
              />
            </div>
            <div className="sm:col-span-2 border-t border-gray-200 pt-5">
              <h2 className="text-lg font-medium text-gray-900">
                Price information
              </h2>
              <div className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                <div className="sm:col-span-1 sm:col-start-1">
                  <label className="block text-sm font-medium text-gray-700">
                    MSRP
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="price"
                      id="price"
                      value={entry.price}
                      className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setEntry({
                          ...entry,
                          price: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Secondary Lower
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="age"
                      id="age"
                      value={entry.secondary}
                      className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setEntry({
                          ...entry,
                          secondary: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Secondary Upper
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="age"
                      id="age"
                      value={entry.secondaryOuterBound}
                      className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setEntry({
                          ...entry,
                          secondaryOuterBound: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2 border-t border-gray-200 pt-5">
              <h2 className="text-lg font-medium text-gray-900">
                Age information
              </h2>
              <div className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                <div className="sm:col-span-1 sm:col-start-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Years old
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="age"
                      id="age"
                      value={entry.age}
                      className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setEntry({
                          ...entry,
                          age: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Months old
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="age"
                      id="age"
                      value={entry.monthsOld}
                      className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setEntry({
                          ...entry,
                          monthsOld: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Days old
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="age"
                      id="age"
                      value={entry.daysOld}
                      className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setEntry({
                          ...entry,
                          daysOld: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2 border-t border-gray-200 pt-5">
              <h2 className="text-lg font-medium text-gray-900">
                Origin information
              </h2>
              <div className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"></div>
              <label
                htmlFor="origin"
                className="block text-sm font-medium text-black/80 mb-1"
              >
                Origin
              </label>
              <input
                type="text"
                name="origin"
                id="origin"
                value={entry.origin}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                onChange={(e) =>
                  setEntry({
                    ...entry,
                    origin: e.target.value,
                  })
                }
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="distillery"
                className="block text-sm font-medium text-black/80 mb-1"
              >
                Distillery
              </label>
              <Combobox
                value={selectedDistillery}
                onChange={(distillery: Distillery | null) => {
                  setSelectedDistillery(distillery);
                  onAddDistilleryToJuice(distillery);
                }}
              >
                <div className="relative mt-1">
                  <Combobox.Input
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                    displayValue={(distillery: Distillery | null) =>
                      distillery?.name || ""
                    }
                    onChange={(event) => setDistilleryQuery(event.target.value)}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setDistilleryQuery("")}
                  >
                    <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {Array.isArray(filteredDistilleries) &&
                        filteredDistilleries?.map((distillery: Distillery) => (
                          <Combobox.Option
                            key={distillery.id}
                            className={({ active }) =>
                              cn(
                                "relative cursor-default select-none py-2 pl-10 pr-4",
                                active
                                  ? "bg-black/10 text-black"
                                  : "text-gray-900"
                              )
                            }
                            value={distillery}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={cn(
                                    "block truncate",
                                    selected ? "font-medium" : "font-normal"
                                  )}
                                >
                                  {distillery.name}
                                </span>
                                {selected && (
                                  <span
                                    className={cn(
                                      "absolute inset-y-0 left-0 flex items-center pl-3",
                                      active ? "text-white" : "text-black"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
            <div className="sm:col-span-2 border-t border-gray-200 pt-5">
              <h2 className="text-lg font-medium text-gray-900">
                Other information
              </h2>
              <div className="sm:col-span-6">
                <label
                  htmlFor="rarity"
                  className="block text-sm font-medium text-black/80 mb-2"
                >
                  Rarity
                </label>
                <select
                  id="rarity"
                  name="rarity"
                  value={entry.rarity}
                  onChange={(e) =>
                    setEntry({
                      ...entry,
                      rarity: e.target.value,
                    })
                  }
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                >
                  <option value="" disabled selected>
                    Select a rarity
                  </option>
                  {rarityOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="variety"
                    className="block text-sm font-medium text-black/80 mb-2"
                  >
                    Variety
                  </label>
                  <select
                    id="variety"
                    name="variety"
                    value={entry.variety}
                    onChange={(e) =>
                      setEntry({
                        ...entry,
                        variety: e.target.value,
                      })
                    }
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  >
                    <option value="" disabled selected>
                      Select a variety
                    </option>
                    {varietyOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="release"
                    className="block text-sm font-medium text-black/80 mb-2"
                  >
                    Release
                  </label>
                  <select
                    id="release"
                    name="release"
                    value={entry.release}
                    onChange={(e) =>
                      setEntry({
                        ...entry,
                        release: e.target.value,
                      })
                    }
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  >
                    <option value="" disabled selected>
                      Select a release
                    </option>
                    {releaseOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <fieldset>
                <div className="relative flex items-start pt-4">
                  <div className="min-w-0 flex-1 text-sm leading-6">
                    <label className="select-none font-medium text-gray-900">
                      Pickable
                    </label>
                  </div>
                  <div className="ml-3 flex h-6 items-center">
                    <input
                      type="checkbox"
                      checked={entry.pickable}
                      onChange={(e) => {
                        setEntry({
                          ...entry,
                          pickable: e.target.checked,
                        });
                      }}
                      className="h-4 w-4 rounded border text-black focus:ring-black"
                    />
                  </div>
                </div>
              </fieldset>
              {!entry.pickable && (
                <fieldset>
                  <legend className="text-base font-semibold leading-6 text-gray-900">
                    Single barrel attributes
                  </legend>
                  <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                    {pickables.map((pickable, pcikableIdx) => (
                      <div
                        key={pcikableIdx}
                        className="relative flex items-start py-4"
                      >
                        <div className="min-w-0 flex-1 text-sm leading-6">
                          <label
                            htmlFor={`person-${pickable.key}`}
                            className="select-none font-medium text-gray-900"
                          >
                            {pickable.value}
                          </label>
                        </div>
                        <div className="ml-3 flex h-6 items-center">
                          <input
                            id={`person-${pickable.key}`}
                            name={`person-${pickable.key}`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-black focus:ring-black"
                            checked={
                              selectedOptions.includes(pickable.key) ||
                              entry.optionals?.includes(pickable.key)
                            }
                            onChange={(e) => onChangePickable(e, pickable.key)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </fieldset>
              )}
            </div>
          </div>
          <div className="pt-5">
            <button
              type="submit"
              className="inline-flex w-full justify-center rounded-md bg-black mb-3 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black sm:col-start-2"
            >
              Save
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => onSelectSubmission()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubmissionForm;
