import { Juice } from "../../api/server/types";
import {
  DISTILLERY_JUICES_QUERY,
  useGetDistilleryJuices,
} from "../../api/server/queries";
import { useEffect, useState } from "react";

import SearchBar from "../SearchBar";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DistilleryJuiceCard } from "./DistilleryJuiceCard";
import { Pagination } from "@mui/material";
import { AddEditJuiceDrawer } from "../AddEditJuiceDrawer";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

const DistilleryJuices = () => {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  const { data, isLoading, refetch } = useGetDistilleryJuices(
    id as string,
    searchTerm,
    page
  );

  const queryClient = useQueryClient();
  const onCreateSuccessHandle = () => {
    queryClient.invalidateQueries(DISTILLERY_JUICES_QUERY);
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  useEffect(() => {
    setIsRefetching(true);
    refetch().finally(() => setIsRefetching(false));
  }, [debouncedSearchTerm, refetch]);

  const handleAddJuice = () => {
    setModalOpen(true);
  };

  const addJuiceData = {
    distillery: { id },
    release: "ongoing",
    variety: "bourbon",
  } as Juice;

  return (
    <>
      <section className='h-full rounded-xl border bg-card text-card-foreground shadow-sm flex-1 py-4 px-8 flex flex-col'>
        <div className='flex items-center justify-between'>
          <p className='text-xl md:text-3xl'>
            {isLoading || isRefetching ? (
              <CircularProgress size={28} />
            ) : (
              `${data?.total || 0} Products`
            )}
          </p>

          <div className='mt-3 sm:ml-4 sm:mt-0'>
            <button
              onClick={handleAddJuice}
              className='inline-flex items-center rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black'
            >
              <FontAwesomeIcon icon={faPlus} className='mr-3' />
              New Juice
            </button>
          </div>
        </div>

        <div className='my-4'>
          <SearchBar
            setSearchTerm={setSearchTerm}
            setIsRefetching={setIsRefetching}
          />
        </div>

        <div className='flex-1 overflow-auto -mx-8 px-8 relative flex flex-col gap-4'>
          {(isLoading || isRefetching) && (
            <div className='flex items-center justify-center absolute inset-0 z-10 bg-white/40'>
              <CircularProgress className='' />
            </div>
          )}
          {data?.data.map((juice: Juice) => (
            <DistilleryJuiceCard key={juice.id} juice={juice} />
          ))}
        </div>

        {data && (
          <Pagination
            count={Math.ceil(data.total / 20)}
            page={page}
            onChange={(event, value) => setPage(value)}
            className='flex justify-center items-center mt-2'
          />
        )}
      </section>

      {isModalOpen && (
        <AddEditJuiceDrawer
          onClose={() => setModalOpen(false)}
          currentJuice={addJuiceData}
          isNewJuice
          onCreateSuccess={onCreateSuccessHandle}
        />
      )}
    </>
  );
};

export default DistilleryJuices;
