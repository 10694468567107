import axios, { AxiosInstance } from 'axios';

export const createAxiosInstanceWithAuthInterceptor = (
  baseURL: string
): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseURL,
  });
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return instance;
};