import { AxiosResponse } from 'axios';
import { createAxiosInstanceWithAuthInterceptor } from '../../utils/axiosHelper';
import { keysToCamel } from '../../utils/objects';

const baseUrl = "https://api.onlydrams.app/api/v1";

const authServerInstance = createAxiosInstanceWithAuthInterceptor(baseUrl);

authServerInstance.interceptors.response.use(
    function (response) {
      return keysToCamel(response) as AxiosResponse<any>;
    },
  );

export type signInInput = {
    identifier: string,
    password: string
}

export const signIn = async ({ identifier, password }: signInInput) => {
    const body = {
        user: {
            identifier,
            password
        }
    }
    const response = await authServerInstance.post(`${baseUrl}/odis/sign_in`, body);
    return response.data;
}