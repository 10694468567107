import React, { createContext, useState, useContext, ReactNode } from 'react';

const JuicesContext = createContext<JuicesContextType | undefined>(undefined);

interface JuicesState {
  juices: any[];
  totalCount: number;
}

interface JuicesContextType {
  juicesState: JuicesState;
  setJuicesState: React.Dispatch<React.SetStateAction<JuicesState>>;
}

interface JuicesProviderProps {
  children: ReactNode;
}

export const JuicesProvider: React.FC<JuicesProviderProps> = ({ children }) => {
  const [juicesState, setJuicesState] = useState<JuicesState>({
    juices: [],
    totalCount: 0,
  });

  const value = { juicesState, setJuicesState };

  return (
    <JuicesContext.Provider value={value}>
      {children}
    </JuicesContext.Provider>
  );
};

export const useJuices = (): JuicesContextType => {
  const context = useContext(JuicesContext);
  if (context === undefined) {
    throw new Error('useJuices must be used within a JuicesProvider');
  }
  return context;
};
