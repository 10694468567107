import React from "react";
import { User } from "src/api/server/types";
import { ScaleIcon } from "@heroicons/react/24/outline";

interface UserOverviewProps {
  user?: User;
  isLoading?: boolean;
}

export default function UserOverview({ user, isLoading }: UserOverviewProps) {
  const OverviewCard = ({
    title,
    value,
    icon,
  }: {
    title: string;
    value: string | number;
    icon: React.ReactNode;
  }) => (
    <div className="rounded-xl border bg-card text-card-foreground shadow-sm">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">{icon}</div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="truncate text-sm font-medium text-gray-500">
                {title}
              </dt>
              <dd>
                <div className="text-lg font-medium text-gray-900">{value}</div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );

  const SkeletonCard = () => (
    <div className="rounded-xl border bg-card text-card-foreground shadow-sm">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <div className="h-6 w-6 bg-gray-200 rounded"></div>
          </div>
          <div className="ml-5 w-0 flex-1">
            <div className="h-4 bg-gray-200 rounded w-20 mb-2"></div>
            <div className="h-6 bg-gray-200 rounded w-24"></div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-8">
      <div className="mx-auto max-w-6xl">
        <h2 className="text-lg font-medium leading-6 text-gray-900">
          Overview
        </h2>
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {isLoading || !user ? (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          ) : (
            <>
              <OverviewCard
                title="Total bottles"
                value={user.collectionCount}
                icon={
                  <ScaleIcon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                }
              />
              <OverviewCard
                title="Collection value"
                value={`$${user.collectionValue?.toLocaleString() || "0"}`}
                icon={
                  <ScaleIcon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                }
              />
              <OverviewCard
                title="Last sign in IP"
                value={user.lastSignInIp || "Unknown"}
                icon={
                  <ScaleIcon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                }
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
