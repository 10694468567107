import React from "react";
import { User } from "../../api/server/types";
import SearchInput from "../common/SearchInput";
import avatar from "../../assets/default.png";

interface UserListProps {
  users: User[];
  onUserClick: (userId: string) => void;
  searchTerm: string;
  onSearchChange: (term: string) => void;
  totalUsers: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  isLoading: boolean;
}

export default function UserList({
  users,
  onUserClick,
  searchTerm,
  onSearchChange,
  totalUsers,
  currentPage,
  onPageChange,
  isLoading,
}: UserListProps) {
  const itemsPerPage = 20;
  const totalPages = Math.ceil(totalUsers / itemsPerPage);

  const SkeletonLoader = () => (
    <li className="animate-pulse">
      <div className="relative flex items-center space-x-3 px-6 py-5">
        <div className="flex-shrink-0">
          <div className="h-10 w-10 rounded-full bg-gray-200"></div>
        </div>
        <div className="min-w-0 flex-1">
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
          <div className="h-3 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>
    </li>
  );

  return (
    <aside className="hidden w-96 flex-shrink-0 border-r xl:order-first xl:flex xl:flex-col h-screen overflow-auto">
      <div className="px-6 pb-4 pt-6 border-b sticky top-0 left-0 bg-white z-10">
        <h2 className="text-lg font-medium text-gray-900 pb-2">
          All users{" "}
          <span className="text-gray-400 font-light">| {totalUsers}</span>
        </h2>
        <SearchInput
          value={searchTerm}
          onChange={onSearchChange}
          placeholder="Search users"
        />
      </div>
      <ul className="relative z-0 divide-y divide-gray-200">
        {isLoading ? (
          Array.from({ length: itemsPerPage }).map((_, index) => (
            <SkeletonLoader key={index} />
          ))
        ) : users.length === 0 ? (
          <li className="px-6 py-5 text-center">No users found</li>
        ) : (
          users.map((user) => (
            <li key={user.id} onClick={() => onUserClick(user.id)}>
              <div className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={user.avatarUrl || avatar}
                    alt=""
                  />
                </div>
                <div className="min-w-0 flex-1">
                  <a href="#" className="focus:outline-none">
                    <span
                      className="absolute inset-0"
                      aria-hidden="true"
                    ></span>
                    <p className="text-sm font-medium text-gray-900">
                      {user.username}
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      {user.collectionCount} bottles
                    </p>
                  </a>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
      <div className="px-6 py-4 border-t">
        <nav className="flex items-center justify-between">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1 || isLoading}
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-sm text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages || isLoading}
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400 disabled:opacity-50"
          >
            Next
          </button>
        </nav>
      </div>
    </aside>
  );
}
