import { cn } from "src/utils/utils";

export enum SubmissionsTab {
  MySubmissions = "My submissions",
  Submissions = "Submissions",
}

const SubmissionsNavTabs = ({
  activeTab,
  onChangeActiveTab,
}: {
  activeTab: SubmissionsTab;
  onChangeActiveTab: (tab: SubmissionsTab) => void;
}) => {
  return (
    <nav
      className="flex pb-2"
      aria-label="Tabs"
    >
      {Object.values(SubmissionsTab).map((tab) => (
        <button
          key={tab}
          className={cn(
            activeTab === tab
              ? "border-b-2 border-black text-black "
              : "border-b border-transparent text-gray-500 border-gray-200 hover:border-b-2 hover:border-gray-300 hover:text-gray-700",
            "w-1/2  py-4 px-1 text-center text-sm font-medium"
          )}
          aria-current={activeTab === tab ? "page" : undefined}
          onClick={() => onChangeActiveTab(tab)}
        >
          {tab}
        </button>
      ))}
    </nav>
  );
};

export default SubmissionsNavTabs;
