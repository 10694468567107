import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/sidebar";
import StaticSidebar from "./components/staticsidebar";
import Dashboard from "./pages/dashboard";
import Distilleries from "./pages/distilleries";
import Juices from "./pages/juices";
import Stores from "./pages/stores";
import Submissions from "./pages/submissions";
import Login from "./pages/login";
import Inventory from "./pages/inventory";
import Distillery from "./pages/distillery";
import Juice from "./pages/juice";
import Users from "./pages/users";
import User from "./pages/user";
import Store from "./pages/stores/show";
import Products from "./pages/products/index";
import Admin from "./pages/admin/index";
import Varieties from "./pages/admin/varieties/index";
import { ProductsProvider } from "./context/ProductsContext";
import { JuicesProvider } from "./context/JuicesContext";
import { NotificationProvider } from "./context/NotificationContext";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer, Slide } from "react-toastify";
import { Toaster } from "sonner";
import "react-toastify/dist/ReactToastify.css";

import "./tailwind.css";
import ErrorModal from "./components/ErrorModal";

interface AppContentProps {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { staleTime: 60000, refetchOnWindowFocus: false },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        transition={Slide}
        autoClose={4000}
        closeButton
        closeOnClick={false}
        draggable={false}
        style={{ marginTop: "65px", width: "450px", marginLeft: "-138px" }}
      />
      <AuthProvider>
        <ProductsProvider>
          <Router>
            <AppContent
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </Router>
        </ProductsProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

const AppContent: React.FC<AppContentProps> = ({
  sidebarOpen,
  setSidebarOpen,
}) => {
  const { isLoggedIn } = useAuth();

  return (
    <NotificationProvider>
      <ErrorModal />
      <div>
        {isLoggedIn ? (
          <>
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
            <StaticSidebar />

            <main className="lg:pl-72 bg-gray-40">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/distilleries" element={<Distilleries />} />
                <Route path="/distilleries/:id" element={<Distillery />} />
                <Route
                  path="/juices"
                  element={
                    <JuicesProvider>
                      <Juices />
                    </JuicesProvider>
                  }
                />
                <Route path="/Submissions" element={<Submissions />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/:id" element={<User />} />
                <Route path="/juices/:id" element={<Juice />} />
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/stores" element={<Stores />} />
                <Route path="/stores/:id" element={<Store />} />
                <Route path="/products" element={<Products />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/varieties" element={<Varieties />} />
                <Route path="/" element={<Navigate to="/juices" replace />} />
              </Routes>
              <Toaster />
            </main>
          </>
        ) : (
          <Login />
        )}
      </div>
    </NotificationProvider>
  );
};

export default App;
