import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import { Submission } from "src/api/server/types";
import {
  useDeleteSubmissionMutation,
  useSaveRejectReasonMutation,
} from "../../api/server/mutations";
import { toast } from "react-toastify";
import CollapseContainer from "src/components/CollapseContainer";

const reasonOptions = [
  "Invalid spirit type (not a whiskey/whisky)",
  "UPC not visible/readable",
  "UPC does not match photo",
  "Bottle details not provided/ not readable in photo",
  "Not enough information provided",
  "Bottle already in database",
  "Other",
];

const DeleteModal = ({
  submission,
  refetchSubmissions,
  isOpen,
  onToggleIsOpen,
}: {
  submission: Submission | null;
  refetchSubmissions: () => void;
  isOpen: boolean;
  onToggleIsOpen: (isOpen: boolean) => void;
}) => {
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const otherReasonIsOpen = reason === "Other";

  const isDisabled = !reason || (otherReasonIsOpen && !otherReason);

  const deleteSubmissionMutation = useDeleteSubmissionMutation();
  const saveRejectReasonMutation = useSaveRejectReasonMutation();

  useEffect(() => {
    if (!isOpen) {
      setReason("");
      setOtherReason("");
    }
  }, [isOpen]);

  const handleDeleteSubmission = async () => {
    if (!submission) return;

    try {
      await saveRejectReasonMutation.mutateAsync({
        code: submission.id,
        reason: otherReasonIsOpen ? `Other reason: ${otherReason}` : reason,
      });
      await deleteSubmissionMutation.mutateAsync({ code: submission.id });
      refetchSubmissions();
      toast.success("Submission has been rejected!");
      onToggleIsOpen(false);
    } catch (error) {
      toast.error("Error deleting submission");
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100"
        open={isOpen}
        onClose={() => onToggleIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-100 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative p-5 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <p className="mb-2">
                  Are you sure you want to delete <b>{submission?.name}</b>?
                </p>
                <p className="mb-4">
                  Please provide a reason for rejecting the submission.
                </p>

                <label
                  htmlFor="size"
                  className="block text-sm font-medium text-black/80 mb-1"
                >
                  Reason:
                </label>
                <select
                  id="size"
                  name="size"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mb-3"
                >
                  <option value="" disabled>
                    Select a reason
                  </option>
                  {reasonOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <CollapseContainer isCollapsed={!otherReasonIsOpen}>
                  <div className="pb-3">
                    <label
                      htmlFor="size"
                      className="block text-sm font-medium text-black/80 mb-1"
                    >
                      Other Reason:
                    </label>
                    <textarea
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-3 text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 resize-none"
                    />
                  </div>
                </CollapseContainer>

                <button
                  disabled={isDisabled || !submission}
                  onClick={handleDeleteSubmission}
                  className="inline-flex w-full justify-center rounded-md bg-red-500 mb-3 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black sm:col-start-2 mt-3 disabled:opacity-50"
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black/80 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  onClick={() => onToggleIsOpen(false)}
                >
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteModal;
