import { useEffect, useState } from "react";
import { useGetJuices } from "../api/server/queries";
import { Link } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import { Juice } from "../api/server/types";
import { CircularProgress, Pagination } from "@mui/material";
import JuiceCard from "../components/JuiceCard";

const Juices = () => {
  const [juices, setJuices] = useState([] as Juice[]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isRefetching, setIsRefetching] = useState(false);

  const { data, isLoading, refetch } = useGetJuices(searchTerm, page);

  useEffect(() => {
    if (data && JSON.stringify(data.data) !== JSON.stringify(juices)) {
      setJuices(data.data);
    }
  }, [data, juices]);

  useEffect(() => {
    const fetchJuices = async () => {
      setIsRefetching(true);
      try {
        await refetch();
      } catch (error) {
        console.error("Error fetching juices:", error);
      } finally {
        setIsRefetching(false);
      }
    };

    fetchJuices();
  }, [searchTerm, page, refetch]);

  return (
    <div>
      <div className="m-6">
        <SearchBar
          setSearchTerm={setSearchTerm}
          setIsRefetching={setIsRefetching}
        />
      </div>
      <div className="pb-5 border-t sm:flex sm:items-center sm:justify-between p-5">
        <h2 className="text-3xl font-bold tracking-tight">Juices</h2>
      </div>
      {isLoading || isRefetching ? (
        <div className="flex justify-center items-start pt-32 min-h-screen">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <ul className="">
          {juices.map((juice: Juice) => (
            <Link to={`/juices/${juice.id}`} key={juice.id}>
              <JuiceCard
                key={juice.id}
                juice={juice}
                linkToJuicePage={false}
                showActions={false}
                onClick={() => {
                  console.log("going");
                }}
              />
            </Link>
          ))}
        </ul>
      )}
      {data && (
        <Pagination
          count={Math.ceil(data.total / 20)}
          page={page}
          onChange={(event, value) => setPage(value)}
          className="flex justify-center items-center"
        />
      )}
    </div>
  );
};

export default Juices;
