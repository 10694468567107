import { Blocks } from "lucide-react";

export default function Admin() {
  return (
    <div>
      <div className="border-b border-gray-200 p-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          OnlyDrams Admin Panel
        </h3>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 p-5">
        <div
          key="test"
          className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:bg-gray-50"
        >
          <div className="flex-shrink-0">
            <Blocks />
          </div>
          <div className="min-w-0 flex-1">
            <a href="/admin/varieties" className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-md font-medium text-gray-900">Varieties</p>
              <p className="truncate text-sm text-gray-500">
                Manage bottle varieties
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
